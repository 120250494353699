export const setCompletedStep = (labId: string, step: string) => {
  let prevState = {};
  if (localStorage.getItem("progress")) {
    prevState = JSON.parse(localStorage.getItem("progress"));
  }

  if (prevState[labId] && parseInt(prevState[labId]) >= parseInt(step)) {
    // User already finished with this step - do nothing
  } else {
    localStorage.setItem(
      "progress",
      JSON.stringify({
        ...prevState,
        [labId]: parseInt(step),
      }),
    );
    // Send "storage" event manually, for listener to trigger
    // See: https://stackoverflow.com/questions/35865481/storage-event-not-firing
    window.dispatchEvent(new Event("storage"));
  }
};
export const resetSteps = (labId: string) => {
  let prevState = {};
  if (localStorage.getItem("progress")) {
    prevState = JSON.parse(localStorage.getItem("progress"));
  }

  if (prevState[labId]) {
    localStorage.setItem(
      "progress",
      JSON.stringify({
        ...prevState,
        [labId]: 0,
      }),
    );
    // Send "storage" event manually, for listener to trigger
    // See: https://stackoverflow.com/questions/35865481/storage-event-not-firing
    window.dispatchEvent(new Event("storage"));
  }
};
export const getCompletedStep = (labId): number => {
  if (localStorage.getItem("progress")) {
    const completedStep = JSON.parse(localStorage.getItem("progress"))[labId];
    if (completedStep) {
      return parseInt(completedStep);
    } else {
      return 0;
    }
  } else {
    return 0;
  }
};
