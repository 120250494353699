import { useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import { setCompletedStep } from "../../utils/localstorage";
const AiLabStep4 = () => {
  const history = useHistory();
  let { labId, labStep } = useParams<any>();
  const step = parseInt(labStep);
  useEffect(() => {
    // Step is completed right away
    setCompletedStep(labId, labStep);
  }, []);
  return (
    <div className="w-full h-full" style={{ maxWidth: 640 }}>
      <h1 className="heading-1">Test</h1>
      <p>
        Du körde en omgång i <span className="tag text-white">Spelläge</span> med fem ord. Välj nu i menyn i stället
        <span className="tag text-white"> Ritläge</span>. I det här läget kan du rita vad som helst.
        <br />
      </p>
      <div className="p">
        <br />
        <div className="flex ">
          <span className="mr-6 tag">1.</span>
          <div>Testa att rita ett leende. Dra ett böjt streck som en glad mun. Rensa med soptunnan.</div>
        </div>
        <br />
        <div className="flex ">
          <span className="mr-6 tag">2.</span>
          <div>Rita nu en cirkel.</div>
        </div>
        <br />
      </div>
      <p>
        <span className="abbr">AI</span>&#58;n är bra på att gissa när du ritar, men är otränad på bokstäver och kan
        inte läsa.
      </p>
      <div className="p">
        <br />
        <div className="flex ">
          <span className="mr-6 tag">3.</span>
          <div>Skriv stora &ldquo;A&rdquo; på skärmen.</div>
        </div>
        <br />
        <div className="flex ">
          <span className="mr-6 tag">4.</span>
          <div>Rensa och skriv stora &ldquo;J&rdquo;.</div>
        </div>
        <br />
        <div className="flex ">
          <span className="mr-6 tag">5.</span>
          <div>Skriv en &ldquo;8&rdquo;.</div>
        </div>
        <br />
      </div>
      <p>
        Kanske gissade <span className="abbr">AI</span>&#58;n berg på &ldquo;A&rdquo;, krok på &ldquo;J&rdquo; och
        snögubbe på &ldquo;8&rdquo;.
        <br /> <br />
        <span
          className="tag text-white cursor-pointer"
          onClick={() => {
            history.push(`/${labId}/${step + 1}`);
          }}>
          fortsätt
        </span>
      </p>
      <div className="w-full h-24"></div>
    </div>
  );
};
export default AiLabStep4;
