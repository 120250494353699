import { useHistory, useParams } from "react-router-dom";
import { AnimatePresence, motion } from "framer-motion";
// import { AiOutlineMore } from "react-icons/ai";
import { IoMdClose } from "react-icons/io";
import { Lab } from "../model/lab";
import { useContext, useState } from "react";
import { LabContext } from "../utils/context";
import AlertPopup, { AlertProps } from "./AlertPopup";
import { resetSteps } from "../utils/localstorage";
type NavbarProps = {
  title: string;
};
function Navbar(props: NavbarProps) {
  const { title } = props;
  const history = useHistory();
  const [showConfirmDialog, setShowConfirmDialog] = useState<AlertProps>(null);
  let { labId, labStep } = useParams<any>();
  const step = parseInt(labStep);
  const labs = useContext(LabContext);
  const lab: Lab = labs.find((lab) => labId === lab.id);

  return (
    <>
      <AnimatePresence exitBeforeEnter={true}>
        {showConfirmDialog && <AlertPopup {...showConfirmDialog} />}
      </AnimatePresence>
      <motion.div className="w-full bg-black bg-opacity-25">
        <div className="flex w-full justify-between items-center text-white py-4" style={{ minHeight: "50px" }}>
          <IoMdClose
            className="absolute left-1 w-10 sm:w-14 md:w-16 h-8  text-md cursor-pointer"
            style={{ fill: "white" }}
            onClick={() => {
              if (lab && lab.totalSteps > step) {
                setShowConfirmDialog({
                  action: "EXIT",
                  onConfirm: () => {
                    resetSteps(labId);
                    setShowConfirmDialog(null);
                    history.push(`/`);
                  },
                  onCancel: () => {
                    setShowConfirmDialog(null);
                  },
                });
              } else {
                history.push(`/`);
              }
            }}
          />
          {/* <AiOutlineMore
            className="absolute right-1 w-10 sm:w-14 md:w-16 h-8 text-md cursor-pointer"
            style={{ fill: "white" }}
          /> */}
          <div
            className="w-full text-xl sm:text-2xl md:text-3xl text-center text-white uppercase font-bold"
            style={{ letterSpacing: "0.2em" }}>
            {title}
          </div>
        </div>
      </motion.div>
    </>
  );
}

export default Navbar;
