import { useHistory, useParams } from "react-router-dom";
import { useContext, useEffect, useRef, useState } from "react";
import { motion } from "framer-motion";
import Navbar from "../components/Navbar";
import ProgressBar from "../components/ProgressBar";
import Navigation from "../components/Navigation";
import { LabContext } from "../utils/context";
import { Lab } from "../model/lab";
import React from "react";

function LabStepPage() {
  const history = useHistory();
  const labStepContainer = useRef(null);
  const labs = useContext(LabContext);
  let { labId, labStep } = useParams<any>();
  const [scrollHeight, setScrollHeight] = useState(0);
  const lab: Lab = labs.find((lab: Lab) => {
    return lab.id === labId;
  });
  if (!lab) {
    history.push("/");
  }
  const step = parseInt(labStep);

  const handleScroll = () => {
    if (labStepContainer && labStepContainer.current) {
      setScrollHeight(
        labStepContainer.current.scrollHeight -
          (labStepContainer.current.scrollTop + labStepContainer.current.clientHeight),
      );
    }
  };
  useEffect(() => {
    handleScroll();
  }, [labStepContainer?.current?.scrollHeight]);

  useEffect(() => {
    const handleResize = () => {
      if (labStepContainer && labStepContainer.current) {
        labStepContainer.current.style.height = `calc(${window.innerHeight}px - 106px)`;
      }
      handleScroll();
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  });
  useEffect(() => {
    if (labStepContainer && labStepContainer.current) {
      labStepContainer.current.addEventListener("scroll", handleScroll);
    }
    return () => {
      if (labStepContainer && labStepContainer.current) {
        labStepContainer.current.removeEventListener("scroll", handleScroll);
      }
    };
  }, []);
  return (
    <>
      <motion.div
        // initial={{ opacity: 0 }}
        // animate={{ opacity: 1 }}
        className="font-Source w-full h-screen z-10"
        style={{
          maxWidth: "1200px",
          background: lab.theme.gradientBackground,
        }}>
        {lab && (
          <>
            <div
              className="relative w-full"
              style={{
                backgroundImage: `url(${lab.theme.backgroundUrl})`,
                backgroundPosition: "top",
                backgroundSize: "cover",
              }}>
              <Navbar title={lab.navbarTitle} />
              <ProgressBar step={step} total={lab.totalSteps} />
            </div>
            {/* TODO: Make sure navbar (106px) and navigation (100px) heights are fixed */}
            {/* calc(screen height - navbar - navigation) */}
            <div
              ref={labStepContainer}
              style={{ height: `calc(${window.innerHeight}px - 106px)` }}
              className="overflow-y-auto pt-5 pb-6 px-8 flex justify-center">
              {React.createElement(lab.getStep(step))}
            </div>
            {scrollHeight >= 10 && (
              <div
                className="z-20 cursor-pointer fixed bottom-10 left-0 right-0 m-auto w-12 h-12 text-center rounded-full font-visiarc-icon text-4xl text-white animate-bounce"
                onClick={() => {
                  labStepContainer.current.scroll({
                    top: document.body.offsetHeight,
                    left: 0,
                    behavior: "smooth",
                  });
                }}>
                {"\ue064"}
              </div>
            )}
            <Navigation
              step={step}
              total={lab.totalSteps}
              onNext={() => {
                history.push(`/${labId}/${step + 1}`);
              }}
              onPrev={() => {
                history.push(`/${labId}/${step - 1}`);
              }}
            />
          </>
        )}
      </motion.div>
    </>
  );
}
export default LabStepPage;
