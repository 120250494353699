import { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import CheckboxText from "../../components/CheckboxText";
import { getCompletedStep, setCompletedStep } from "../../utils/localstorage";
const MobiltelefonLabStep4 = () => {
  const history = useHistory();
  let { labId, labStep } = useParams<any>();
  const step = parseInt(labStep);
  const maxPart = 5;
  const [part, setPart] = useState(0);

  function nextPart(nextPart: number) {
    //Handel next Part
    if (nextPart > part) {
      setPart(nextPart);
      if (nextPart === maxPart) {
        setCompletedStep(labId, labStep);
      }
    }
  }
  useEffect(() => {
    //Set boxes checked if step all ready done.
    if (getCompletedStep(labId) >= labStep) {
      setPart(maxPart);
    }
  }, []);

  return (
    <div className="w-full h-full" style={{ maxWidth: 640 }}>
      <p>
        Hålla kontakten kunde man göra genom att ringa med fasta telefonen, för att kolla läget och se hur någon mådde
        till exempel. Alternativet var att träffas.
      </p>
      <br />
      <div className="p">
        <CheckboxText
          text={"När du ska träffa någon hur gör du idag?"}
          defultChecked={part > 0}
          nextPart={() => {
            nextPart(1);
          }}
        />
        <br />
        {/* Part 1 */}
        {part >= 1 && (
          <CheckboxText
            text={"Om du inte har mobilen hur gör du då?"}
            defultChecked={part > 1}
            nextPart={() => {
              nextPart(2);
            }}
          />
        )}
      </div>
      {/* Part 2 */}
      {part >= 2 && (
        <div className="text-center">
          <h2 className="tag">Uppdrag</h2>
        </div>
      )}
      <div className="p">
        {part >= 2 && (
          <CheckboxText
            text={"Du fyller år och ska ha kalas. Utan mobil hur skulle du då bjuda in?"}
            defultChecked={part > 2}
            nextPart={() => {
              nextPart(3);
            }}
          />
        )}

        <br />
        {/* Part 3 */}
        {part >= 3 && (
          <CheckboxText
            text={"Du ska plugga med en kompis? Utan mobilen, hur meddelar och bestämmer ni när och var ni ska ses?"}
            defultChecked={part > 3}
            nextPart={() => {
              nextPart(4);
            }}
          />
        )}
        <br />
        {/* Part 4 */}
        {part >= 4 && (
          <CheckboxText
            text={"Du vill få tag på någon. Ingen har mobil. Hur gör du då?"}
            defultChecked={part > 4}
            nextPart={() => {
              nextPart(5);
            }}
          />
        )}
        <br />
      </div>
      {/* Part 5 */}
      {part >= maxPart && (
        <p>
          <span
            className="tag text-white cursor-pointer"
            onClick={() => {
              history.push(`/${labId}/${step + 1}`);
            }}>
            fortsätt
          </span>
        </p>
      )}
      <div className="w-full h-24"></div>
    </div>
  );
};

export default MobiltelefonLabStep4;
