import { useEffect,  useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import CheckboxText from "../../components/CheckboxText";
import { getCompletedStep, setCompletedStep } from "../../utils/localstorage";

const MobiltelefonLabStep2 = () => {
  const history = useHistory();
  let { labId, labStep } = useParams<any>();
  const step = parseInt(labStep);
  const maxPart = 2;
  const [part, setPart] = useState(0);

  function nextPart(nextPart: number) {
    //Handel next Part
    if (nextPart > part) {
      setPart(nextPart);
      if (nextPart === maxPart) {
        setCompletedStep(labId, labStep);
      }
    }
  }
  useEffect(() => {
    //Set boxes checked if step all ready done.
    if (getCompletedStep(labId) >= labStep) {
      setPart(maxPart);
    }
  }, []);
  return (
    <div className="w-full h-full" style={{ maxWidth: 640 }}>
      <h1 className="heading-1">3G, 4G &amp; 5G </h1>
      <p>
        <span className="tag">Mobiltelefonin </span>
        utvecklades vidare och Ericsson var ofta först att sätta standarder för hastigheten i näten. I början ville man
        ringa och <span className="abbr">SMS</span>&#58;a, men snart kom <span className="abbr">GSM</span> Data och nu
        kunde man börja surfa och hämta data och filer. Idag är det självklart att man kan Googla, vara aktiv på sociala
        medier, spela och kolla på film. Nu kan du vara uppkopplad hela tiden, tack vare innovationer från Linköping. 
        <br/>
        <br/>
        Sedan kom
        <span className="abbr"> 3G</span>, <span className="abbr">4G</span> och <span className="abbr">5G</span>.
        <br />
        <br />
      </p>
      <div className="p">
        <CheckboxText
          text={"Vilka saker på din mobil gör du som kräver surf (mobildata)? När surfen är slut hur känns det då?"}
          defultChecked={part > 0}
          nextPart={() => {
            nextPart(1);
          }}
        />
        <br />
        {/* Part 1 */}
        {part >= 1 && (
          <CheckboxText
            text={"Vad gör du när du har tillgång till fri WiFi?"}
            defultChecked={part > 1}
            nextPart={() => {
              nextPart(2);
            }}
          />
        )}
        <br />
      </div>
      {/* Part 2 */}
      {part >= maxPart && (
        <p>
          <span
            className="tag text-white cursor-pointer"
            onClick={() => {
              history.push(`/${labId}/${step + 1}`);
            }}>
            fortsätt
          </span>
        </p>
      )}
      <div className="w-full h-24"></div>
    </div>
  );
};

export default MobiltelefonLabStep2;
