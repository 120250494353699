import { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import CheckboxText from "../../components/CheckboxText";
import { getCompletedStep, setCompletedStep } from "../../utils/localstorage";

const MobiltelefonLabStep1 = () => {
  const history = useHistory();
  let { labId, labStep } = useParams<any>();
  const step = parseInt(labStep);
  const maxPart = 3;
  const [part, setPart] = useState(0);

  function nextPart(nextPart: number) {
    //Handel next Part
    if (nextPart > part) {
      setPart(nextPart);
      if (nextPart === maxPart) {
        setCompletedStep(labId, labStep);
      }
    }
  }
  useEffect(() => {
    //Set boxes checked if step all ready done.
    if (getCompletedStep(labId) >= labStep) {
      setPart(maxPart);
    }
  }, []);
  return (
    <div className="w-full h-full" style={{ maxWidth: 640 }}>
      <h1 className="heading-1">Mobiltelefon</h1>
      <p>
        <span className="tag">Mobiltelefonin </span> utvecklades först i världen här i Sverige, i Linköping! Det var företaget Ericsson som
        spindeln i nätet och ledde utvecklingen. <span className="abbr">GSM</span>(Globalt system för mobil kommunikation) möjliggjorde att man kunde börja ringa från mobiltelefoner. Världens första{" "}
        <span className="abbr">SMS </span>
        skickades i Linköping Science Park.
        <br />
        <br />
      </p> <p>Nedan följer ett antal frågor att diskutera.</p><br/>
      <div className="p">
        <CheckboxText
          text={"Hur ofta använder du din mobil?"}
          defultChecked={part > 0}
          nextPart={() => {
            nextPart(1);
          }}
        />
        <br />
        {/* Part 1 */}
        {part >= 1 && (
          <CheckboxText
            text={"Har du varit utan din mobil? Glömt den? Utan batteri? Blivit av med den? Hur var det?"}
            defultChecked={part > 1}
            nextPart={() => {
              nextPart(2);
            }}
          />
        )}
        <br />
        {/* Part 2 */}
        {part >= 2 && (
          <CheckboxText
            text={
              "Om du inte kunde ringa dina föräldrar eller kompisar, hur skulle det kännas? Om du inte kunde chatta med dina kompisar, hur skulle din dag då förändras?"
            }
            defultChecked={part > 2}
            nextPart={() => {
              nextPart(3);
            }}
          />
        )}
        <br />
      </div>
      {/* Part 3 */}
      {part >= maxPart && (
        <p>
          <span
            className="tag text-white cursor-pointer"
            onClick={() => {
              history.push(`/${labId}/${step + 1}`);
            }}>
            fortsätt
          </span>
        </p>
      )}
      <div className="w-full h-24"></div>
    </div>
  );
};

export default MobiltelefonLabStep1;
