import { useHistory, useParams } from "react-router-dom";
import { useContext } from "react";
import { motion } from "framer-motion";
import Navbar from "../components/Navbar";
import Button from "../components/Button";
import Tag from "../components/Tag";

import { ReactComponent as LogoLSP } from "../resources/svg/logotypes/logotype_center_heavy_white_srgb.svg";
import { ReactComponent as LogoMadeInLKPG } from "../resources/svg/logotypes/Made-in-Linkoping-neg.svg";
import { Lab } from "../model/lab";
import { LabContext } from "../utils/context";

function LabStartPage() {
  const history = useHistory();
  let { labId } = useParams<any>();
  const labs = useContext(LabContext);
  const lab: Lab = labs.find((lab) => labId === lab.id);
  if (!lab) {
    history.push("/");
  }
  return (
    <>
      <motion.div
        className="font-Source w-full  bottom-0 top-0 fixed"
        style={{
          maxWidth: "1200px",
          backgroundImage: `url(${lab.theme.backdropUrl})`,
          backgroundSize: "cover",
        }}>
        {lab && (
          <>
            <motion.div
              layoutId={lab.id}
              exit={{ opacity: 0, transition: { duration: 0 } }}
              className="relative w-full h-3/5 rounded-b-3xl"
              style={{
                backgroundImage: `url(${lab.theme.backgroundUrl})`,
                backgroundPosition: "center",
                backgroundSize: "cover",
              }}>
              <Navbar title={lab.navbarTitle} />
              <div
                className="rounded-b-3xl absolute bottom-0 w-full p-4 bg-opacity-80 flex flex-col items-start "
                style={{
                  backgroundColor: lab.theme.backgroundColor,
                }}>
                <div className="flex flex-row mb-2 items-center">
                  <div
                    className="h-20 w-20 sm:h-32 sm:w-32 md:h-40 md:w-40 mx-2"
                    style={{
                      backgroundImage: `url(${lab.theme.iconUrl})`,
                      backgroundPosition: "center",
                      backgroundSize: "150%",
                    }}></div>
                  <div className="flex flex-col text-base ml-4 sm:ml-6 md:ml-8">
                    <span className="tag sm:text-lg md:text-xl">{lab.startCard.title}</span>
                    <div className="text-lg sm:text-xl  md:text-2xl leading-6">{lab.startCard.description1}</div>
                    <div className="text-lg sm:text-xl  md:text-2xl leading-6">{lab.startCard.description2}</div>
                    <div className="text-sm sm:text-base md:text-lg font-semibold uppercase tracking-widest">
                      {lab.time}&nbsp;min
                    </div>
                  </div>
                </div>
                <div className="flex flex-row flex-wrap">
                  {lab.categories.map((category, i) => {
                    return <Tag key={i} text={category} color={lab.theme.tagColor} />;
                  })}
                </div>
              </div>
            </motion.div>
          </>
        )}
        <div className="w-full h-1/5 bg-transpatent flex flex-col justify-around items-center">
          <Button
            text={"Starta"}
            onClick={() => {
              history.push(`/${labId}/1`);
            }}
          />
        </div>
        <div className="fixed bottom-4 text-white w-full " style={{ maxWidth: "1200px" }}>
          <hr className="w-11/12 m-auto border-t-2 " />
          <a href="https://linkopingsciencepark.se/" target="_blank" rel="noreferrer">
            <LogoLSP className="w-80 sm:h-16 sm:w-auto md:h-20 m-auto" style={{ minWidth: "320px" }} />
          </a>
          <div className="w-full flex justify-end items-center">
            <div className="text-xs sm:text-sm md:text-base text-right mr-4">
              <span className="tag" style={{ fontSize: "1rem" }}>
                Labbarna &amp; Appen
              </span>{" "}
              designad &amp; utvecklad
              <br /> åt{" "}
              <a href="https://linkopingsciencepark.se/" target="_blank" rel="noreferrer">
                Linköping Science Park
              </a>{" "}
              av{" "}
              <a className="font-visiarc-icon" href="https://visiarc.com/" target="_blank" rel="noreferrer">
                &#57345;
              </a>
              .
            </div>{" "}
            <a href="https://linkopingsciencepark.se/" target="_blank" rel="noreferrer">
              <LogoMadeInLKPG
                className="w-28 sm:w-auto sm:h-12 md:h-16 pr-4 sm:pr-6 md:pr-7"
                style={{ minWidth: "80px" }}
              />
            </a>
          </div>
        </div>
      </motion.div>
    </>
  );
}

export default LabStartPage;
