import { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import CheckboxText from "../../components/CheckboxText";
import { getCompletedStep, setCompletedStep } from "../../utils/localstorage";
const MpegLabStep2 = () => {
  const history = useHistory();
  let { labId, labStep } = useParams<any>();
  const step = parseInt(labStep);
  const maxPart = 2;
  const [part, setPart] = useState(0);

  function nextPart(nextPart: number) {
    //Handel next Part
    if (nextPart > part) {
      setPart(nextPart);
      if (nextPart === maxPart) {
        setCompletedStep(labId, labStep);
      }
    }
  }
  useEffect(() => {
    //Set boxes checked if step all ready done.
    if (getCompletedStep(labId) >= labStep) {
      setPart(maxPart);
    }
  }, []);
  return (
    <div className="w-full h-full" style={{ maxWidth: 640 }}>
      <h1 className="heading-1">Frågor</h1>
      <p>Nedan följer ett antal frågor att diskutera.</p><br/>
      <div className="p">
        <CheckboxText
          text={"Hur använder du video i din vardag? Vilka prylar kan spela upp digital video?"}
          defultChecked={part > 0}
          nextPart={() => {
            nextPart(1);
          }}
        />
        <br />
        {/* Part 1 */}
        {part >= 1 && (
          <CheckboxText
            text={"Vilka appar har du på din mobiltelefon som kan visa och/eller spela in video?"}
            defultChecked={part > 1}
            nextPart={() => {
              nextPart(2);
            }}
          />
        )}
        <br />
      </div>
      {/* Part 2 */}
      {part >= maxPart && (
        <p>
          <span
            className="tag text-white cursor-pointer"
            onClick={() => {
              history.push(`/${labId}/${step + 1}`);
            }}>
            fortsätt
          </span>
        </p>
      )}
      <div className="w-full h-24"></div>
    </div>
  );
};

export default MpegLabStep2;
