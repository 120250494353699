import { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import CheckboxText from "../../components/CheckboxText";
import { getCompletedStep, setCompletedStep } from "../../utils/localstorage";
const AiLabStep3 = () => {
  const history = useHistory();
  let { labId, labStep } = useParams<any>();
  const step = parseInt(labStep);
  const maxPart = 3;
  const [part, setPart] = useState(0);

  function nextPart(nextPart: number) {
    //Handel next Part
    if (nextPart > part) {
      setPart(nextPart);
      if (nextPart === maxPart) {
        setCompletedStep(labId, labStep);
      }
    }
  }
  useEffect(() => {
    //Set boxes checked if step all ready done.
    if (getCompletedStep(labId) >= labStep) {
      setPart(maxPart);
    }
  }, []);
  return (
    <div className="w-full h-full" style={{ maxWidth: 640 }}>
      <h1 className="heading-1">Frågor</h1>
      <p>Nedan följer ett antal frågor att diskutera.</p><br/>
      <div className="p">
        <CheckboxText
          text={
            <div>
              Lyckades du rita ord som <span className="abbr">AI</span>&#58;n kunde gissa?
            </div>
          }
          defultChecked={part > 0}
          nextPart={() => {
            nextPart(1);
          }}
        />
        <br />
        {/* Part 1 */}
        {part >= 1 && (
          <CheckboxText
            text={"Var något ord svårt och som du misslyckades med och fick 0 poäng?"}
            defultChecked={part > 1}
            nextPart={() => {
              nextPart(2);
            }}
          />
        )}
        <br />
        {/* Part 2 */}
        {part >= 2 && (
          <CheckboxText
            text={<div>Hade du på ljudet? Det är kul att höra hur AI&#58;n gissar när man ritar.</div>}
            defultChecked={part > 2}
            nextPart={() => {
              nextPart(3);
            }}
          />
        )}
        <br />
      </div>
      {/* Part 3 */}
      {part >= maxPart && (
        <p>
          Den här <span className="abbr">AI</span>&#58;n är tränad på hundratusentals handritade teckningar.  <span className="abbr">AI</span>&#58;n ser mönster
          och kan därför lyckas gissa rätt väldigt snabbt.
          <br />
          <br />I nästa steg ska du utsätta <span className="abbr">AI</span>&#58;n för saker som den inte är tränad på.
          <br />
          <br />
          <span
            className="tag text-white cursor-pointer"
            onClick={() => {
              history.push(`/${labId}/${step + 1}`);
            }}>
            fortsätt
          </span>
        </p>
      )}
      <div className="w-full h-24"></div>
    </div>
  );
};
export default AiLabStep3;
