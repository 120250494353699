import { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { turnOffVideoStream } from "../../utils";
import { setCompletedStep } from "../../utils/localstorage";

const ArLabStep3 = () => {
  const [feedbackInfo, setFeedbackInfo] = useState("");
  const [done, setDone] = useState(false);
  let { labId, labStep } = useParams<any>();
  const history = useHistory();
  const step = parseInt(labStep);

  useEffect(() => {
    var scene, camera, renderer;
    //  clock, deltaTime, totalTime;
    var arToolkitSource, arToolkitContext;
    var markerRoots = [];
    var patternArray;
    var animateId;

    function initialize() {
      //@ts-ignore
      scene = new THREE.Scene();

      //@ts-ignore
      const hemiLight = new THREE.HemisphereLight(0xffffff, 0x444444);
      hemiLight.position.set(0, 200, 0);
      scene.add(hemiLight);

      //@ts-ignore
      camera = new THREE.Camera();
      scene.add(camera);

      //@ts-ignore
      renderer = new THREE.WebGLRenderer({
        antialias: true,
        alpha: true,
      });
      //@ts-ignore
      renderer.setClearColor(new THREE.Color("lightgrey"), 0);
      renderer.setSize(1280, 920);
      renderer.domElement.style.position = "absolute";
      renderer.domElement.style.top = "0px";
      renderer.domElement.style.left = "0px";
      renderer.domElement.style.zIndex = "100";

      document.getElementById("ar-render-container").appendChild(renderer.domElement);

      //@ts-ignore
      // clock = new THREE.Clock();
      // deltaTime = 0;
      // totalTime = 0;

      ////////////////////////////////////////////////////////////
      // setup arToolkitSource
      ////////////////////////////////////////////////////////////

      //@ts-ignore
      arToolkitSource = new THREEx.ArToolkitSource({
        sourceType: "webcam",
      });

      function onResize() {
        if (arToolkitSource) {
          arToolkitSource.onResizeElement();
          arToolkitSource.copyElementSizeTo(renderer.domElement);
          if (arToolkitContext.arController !== null) {
            arToolkitSource.copyElementSizeTo(arToolkitContext.arController.canvas);
          }
        }
      }

      arToolkitSource.init(function onReady() {
        onResize();
      });

      // handle resize event
      window.addEventListener("resize", function () {
        onResize();
      });

      ////////////////////////////////////////////////////////////
      // setup arToolkitContext
      ////////////////////////////////////////////////////////////

      // create atToolkitContext
      //@ts-ignore
      arToolkitContext = new THREEx.ArToolkitContext({
        cameraParametersUrl: "/data/camera_para.dat",
        detectionMode: "mono",
      });

      // copy projection matrix to camera when initialization complete
      arToolkitContext.init(function onCompleted() {
        if (camera) {
          camera.projectionMatrix.copy(arToolkitContext.getProjectionMatrix());
        }
      });

      ////////////////////////////////////////////////////////////
      // setup markerRoots
      ////////////////////////////////////////////////////////////

      patternArray = [
        "0",
        "1",
        "2",
        "3",
        "4",
        "5",
        "6",
        "7",
        "8",
        "9",
        "a1",
        "a2",
        "a3",
        "b",
        "c1",
        "c2",
        "d",
        "e",
        "f",
        "g1",
        "g2",
        "h",
        "i",
        "j",
        "k",
        "l",
        "m1",
        "m2",
        "m3",
        "n",
        "o",
        "p",
        "q",
        "r",
        "s",
        "t",
        "u",
        "v",
        "w",
        "x",
        "y",
        "z",
        "å",
        "ö",
      ];

      //@ts-ignore
      var objLoader = new THREE.OBJLoader();
      objLoader.setPath("/models/");
      for (let i = 0; i < patternArray.length; i++) {
        //@ts-ignore
        let markerRoot = new THREE.Group();
        markerRoots.push(markerRoot);
        scene.add(markerRoot);
        //@ts-ignore
        new THREEx.ArMarkerControls(arToolkitContext, markerRoot, {
          type: "pattern",
          patternUrl: "/data/" + patternArray[i] + ".patt",
        });

        if (patternArray[i] === "m2") {
          objLoader.load("M.obj", function (object) {
            //@ts-ignore
            const material = new THREE.MeshPhongMaterial({ color: 0xf34497 });
            object.traverse(function (child) {
              //@ts-ignore
              if (child instanceof THREE.Mesh) {
                child.material = material;
              }
            });
            object.position.set(0, 0.3, 0);
            object.scale.set(0.003, 0.003, 0.003);

            markerRoot.add(object);
          });
        } else {
          //@ts-ignore
          var mtlLoader = new THREE.MTLLoader();
          mtlLoader.setPath("/models/");
          mtlLoader.load("materials.mtl", function (materials) {
            materials.preload();
            //@ts-ignore
            var objLoader = new THREE.OBJLoader();
            objLoader.setMaterials(materials);
            objLoader.setPath("/models/");
            objLoader.load("model.obj", function (object) {
              object.position.set(0.1, 0.3, 0);
              object.rotation.set(0, -Math.PI / 2, 0);
              object.scale.set(1, 1, 1);
              markerRoot.add(object);
            });
          });
        }
      }
    }

    function update() {
      // update artoolkit on every frame
      if (arToolkitSource.ready !== false) arToolkitContext.update(arToolkitSource.domElement);

      for (let i = 0; i < markerRoots.length; i++) {
        if (markerRoots[i].visible) {
          if (patternArray[i] === "m2") {
            setDone(true);
            setCompletedStep(labId, labStep);
          } else {
            setFeedbackInfo("Nix, tyvärr. Leta vidare.");
          }
        }
      }
    }

    function render() {
      renderer.render(scene, camera);
    }

    function animate() {
      animateId = requestAnimationFrame(animate);
      // deltaTime = clock.getDelta();
      // totalTime += deltaTime;

      update();
      render();
    }

    try {
      initialize();
      animate();
    } catch (error) {
      console.error("ArLabStep3 failed to initilize THREE");
    }

    return () => {
      // clean-up
      turnOffVideoStream();
      cancelAnimationFrame(animateId);
      scene = null;
      camera = null;
      renderer = null;
      // clock = null;
      // deltaTime = null;
      // totalTime = null;
      arToolkitSource = null;
      arToolkitContext = null;
      markerRoots = null;
      patternArray = null;
    };
  }, []);

  return (
    <div className="w-full h-full">
      <p style={{ paddingBottom: 14 }}>Hitta kortet med den självkörande bilen och rikta kameran mot den.</p>
      <div
        id="ar-render-container"
        style={{ height: "calc(100% - 200px)", border: "10px solid white" }}
        className="h-full relative overflow-hidden flex justify-center items-center">
        <div
          className="absolute transition-all duration-1000 py-3 text-center text-white rounded-full"
          style={{
            zIndex: 150,
            bottom: done ? 20 : -80,
            width: "90%",
            background: "linear-gradient(90deg, rgba(238,67,156,1) 0%, rgba(142,55,242,1) 100%)",
          }}>
          M som i mobilitet
        </div>
      </div>
      {!done && <p style={{ paddingTop: 14 }}>{feedbackInfo}</p>}
      {done && (
        <p style={{ paddingTop: 14 }}>
          Bra jobbat.{" "}
          <span
            className="tag text-white cursor-pointer"
            onClick={() => {
              history.push(`/${labId}/${step + 1}`);
            }}>
            fortsätt
          </span>
        </p>
      )}
    </div>
  );
};

export default ArLabStep3;
