import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import backgroundImage from "./resources/img/backdrops/Peak-Noise-BW-0.1.jpg";
import Home from "./pages/Home";
import reportWebVitals from "./reportWebVitals";
import { Route, Router, Switch, useLocation } from "react-router-dom";
import { createBrowserHistory } from "history";
import { I18nextProvider } from "react-i18next";
import i18n from "./i18n";
import moment from "moment";
import "moment/locale/sv";
import LabStartPage from "./pages/LabStartPage";
import LabStepPage from "./pages/LabStepPage";
import About from "./pages/About";
import { LabContext } from "./utils/context";
import arLab from "./labs/ar-lab/initArLab";
import aiLab from "./labs/ai-lab/initAiLab";
import mobileLab from "./labs/mobile-lab/initMobileLab";
import mpegLab from "./labs/mpeg-lab/initMpegLab";
import { AnimatePresence, AnimateSharedLayout } from "framer-motion";

const labs = [aiLab, arLab, mobileLab, mpegLab];

const hist = createBrowserHistory();
var lang = navigator.languages;
let sv = false;
if (lang && lang.length) {
  lang.forEach((l) => {
    if (l.includes("sv")) {
      sv = true;
    }
  });
}
i18n.changeLanguage(sv ? "sv" : "en");
moment.locale(sv ? "sv" : "en");
ReactDOM.render(
  <React.StrictMode>
    <I18nextProvider i18n={i18n}>
      <LabContext.Provider value={labs}>
        <Router history={hist}>
          <RouteSwitch />
        </Router>
      </LabContext.Provider>
    </I18nextProvider>
  </React.StrictMode>,
  document.getElementById("root"),
);

function RouteSwitch() {
  const location = useLocation();
  return (
    <div
      className="flex justify-center w-screen h-screen"
      style={{ backgroundImage: `url(${backgroundImage})`, backgroundSize: "cover", backgroundPosition: "center" }}>
      <AnimateSharedLayout type="crossfade">
        <AnimatePresence exitBeforeEnter>
          <Switch location={location} key={location.pathname}>
            <Route path="/about" component={About} />
            <Route path="/:labId/:labStep" component={LabStepPage} />
            <Route path="/:labId" component={LabStartPage} />
            <Route path="/" component={Home} />
            <Route path="" component={Home} />
          </Switch>
        </AnimatePresence>
      </AnimateSharedLayout>
    </div>
  );
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
