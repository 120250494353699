import { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import CheckboxText from "../../components/CheckboxText";
import { getCompletedStep, setCompletedStep } from "../../utils/localstorage";
const MobiltelefonLabStep5 = () => {
  const history = useHistory();
  let { labId, labStep } = useParams<any>();
  const step = parseInt(labStep);
  const maxPart = 1;
  const [part, setPart] = useState(0);

  function nextPart(nextPart: number) {
    //Handel next Part
    if (nextPart > part) {
      setPart(nextPart);
      if (nextPart === maxPart) {
        setCompletedStep(labId, labStep);
      }
    }
  }
  useEffect(() => {
    //Set boxes checked if step all ready done.
    if (getCompletedStep(labId) >= labStep) {
      setPart(maxPart);
    }
  }, []);
  return (
    <div className="w-full h-full" style={{ maxWidth: 640 }}>
      <p>
        <span className="tag text-white">Kalaset. </span>
        Vanligt var att man skrev en inbjudan på ett inbjudningskort som man la i ett kuvert, skrev addressen på, satte
        på ett frimärke och postade. Fick man en inbjudan sparade man den och skrev ned i en kalender (av papper) så man
        inte skulle glömma. Undrade man om någon skulle komma ringde man (på kvällen när de var hemma, i telefonen med
        sladd).
        <br />
        <br />
        <span className="tag text-white">Plugga tillsammans. </span>
        Säkert bestämde man en plats och tid redan i skolan. Sedan var det bara att dyka upp och hålla tiden.
        <br />
        <br />
        <span className="tag text-white">Var är du? </span>Vanligt var att man ringde med den fasta telefonen om man
        visste att kompisen var inne. Inte alls ovanligt var att man gick och ringde på dörren.(Det var ju lite jobbigt
        att gå hem, komma ihåg numret och ringa).
      </p>
      <br />
      <div className="p">
        <CheckboxText
          text={"Har du någonsin gått hem till någon och ringt på dörren helt utan förvarning?"}
          defultChecked={part > 0}
          nextPart={() => {
            nextPart(1);
          }}
        />
        <br />
      </div>
      {/* Part 1 */}
      {part >= maxPart && (
        <p>
          <span
            className="tag text-white cursor-pointer"
            onClick={() => {
              history.push(`/${labId}/${step + 1}`);
            }}>
            fortsätt
          </span>
        </p>
      )}
      <div className="w-full h-24"></div>
    </div>
  );
};

export default MobiltelefonLabStep5;
