import { useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import { setCompletedStep } from "../../utils/localstorage";

const MpegLabStep3 = () => {
  const history = useHistory();
  let { labId, labStep } = useParams<any>();
  const step = parseInt(labStep);
  useEffect(() => {
    // Step is completed right away
    setCompletedStep(labId, labStep);
  }, []);
  return (
    <div className="w-full h-full" style={{ maxWidth: 640 }}>
      <h1 className="heading-1">Digital video</h1>
      <p>
        <span className="tag">MPEG </span> (eller rörlig digital media) finns precis överallt.
        <br />
        <br />
      </p>
      <div className="p">
        <div className="flex ">
          <div className="mr-6 text-white">■</div>
          <div>
            <span className="tag">Tekniska prylar </span>som kan spela video är så klart mobilen, surfplattan och
            datorn, men även moderna TV-apparater och spelkonsoler. Kanske nämnde du alla.
          </div>
        </div>
        <br />
        <div className="flex ">
          <div className="mr-6 text-white">■</div>
          <div>
            <span className="tag">Appar. </span>Streamingtjänster som YouTube är videobaserade. Andra tjänster som
            amerikanska Netflix, HBO, Disney med flera och svenska tjänster som SVT Play och Viaplay skulle inte
            existera utan <span className="abbr">MPEG</span>.
          </div>
        </div>
        <br />
      </div>
      <p>
        <span
          className="tag text-white cursor-pointer"
          onClick={() => {
            history.push(`/${labId}/${step + 1}`);
          }}>
          fortsätt
        </span>
      </p>
      <div className="w-full h-24"></div>
    </div>
  );
};

export default MpegLabStep3;
