import { useHistory, useParams } from "react-router-dom";
import { motion } from "framer-motion";
import { MdOutlineNavigateNext } from "react-icons/md";
import React, { useContext, useEffect, useState } from "react";
import { LabContext } from "../utils/context";
import { Lab } from "../model/lab";
import { getCompletedStep } from "../utils/localstorage";
type NavigationProps = {
  step: number;
  total: number;
  onNext: React.MouseEventHandler<SVGElement | HTMLDivElement>;
  onPrev: React.MouseEventHandler<SVGElement | HTMLDivElement>;
};

function Navigation(props: NavigationProps) {
  const { step, total, onNext, onPrev } = props;
  const history = useHistory();
  const [nextStepLocked, setNextStepLocked] = useState(false);

  const labs = useContext(LabContext);
  let { labId } = useParams<any>();

  const lab: Lab = labs.find((lab: Lab) => {
    return lab.id === labId;
  });

  useEffect(() => {
    const checkLocalStorage = () => {
      if (lab.forceCompleteEachStep) {
        const completedStep = getCompletedStep(labId);
        if (step === completedStep + 1) {
          setNextStepLocked(true);
        } else if (step > completedStep) {
          history.push(`/${labId}/${completedStep + 1}`);
        } else {
          // Not locked
          setNextStepLocked(false);
        }
      }
    };

    window.addEventListener("storage", checkLocalStorage);
    checkLocalStorage();

    return () => {
      window.removeEventListener("storage", checkLocalStorage);
    };
  }, []);

  let navigationSvgProps: any = { style: { height: 100, width: "100%" }, preserveAspectRatio: "none" };

  return (
    <>
      <motion.div
        className="w-full fixed bottom-0"
        style={{
          height: "100px",
          maxWidth: "1200px",
          pointerEvents: "none",
        }}>
        {React.createElement(lab.theme.getNavigationBackground(), navigationSvgProps, null)}
        {step < total && !nextStepLocked && (
          <>
            <MdOutlineNavigateNext
              className="absolute right-1 bottom-2 z-10 h-12 w-12 text-md cursor-pointer"
              style={{ fill: "white", pointerEvents: "auto" }}
              onClick={onNext}
            />
            <div className="absolute right-0 bottom-0 z-0 w-16" onClick={onNext}>
              {lab && React.createElement(lab.theme.getNavigationNext())}
            </div>
          </>
        )}
        {step > 1 && (
          <>
            <MdOutlineNavigateNext
              className="absolute z-10 transform transition-all rotate-180 left-1 bottom-2 h-12 w-12 text-md cursor-pointer"
              style={{ fill: "white", pointerEvents: "auto" }}
              onClick={onPrev}
            />
            <div className="absolute left-0 bottom-0 z-0 w-16" onClick={onPrev}>
              {React.createElement(lab.theme.getNavigationPrev())}
            </div>
          </>
        )}
      </motion.div>
    </>
  );
}

export default Navigation;
