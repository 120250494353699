import { useEffect, useRef } from "react";
import { IoMdArrowBack } from "react-icons/io";
import { useParams } from "react-router-dom";
import { setCompletedStep } from "../../utils/localstorage";
import AiIcon from "../../resources/svg/icons/AI-pos.svg";

const AiLabStep0 = () => {
  let { labId, labStep } = useParams<any>();
  const fullsceen = useRef(null);
  useEffect(() => {
    // Step is completed right away
    setCompletedStep(labId, labStep);
  }, []);
  return (
    <div className="w-full h-full" style={{ maxWidth: 640 }}>
      {/* Fullscreen of image */}
      <div
        ref={fullsceen}
        onClick={() => {
          fullsceen.current.classList.toggle("hidden");
        }}
        className="hidden fixed top-0 left-0 right-0 bottom-0 z-50 bg-white bg-opacity-95">
        <IoMdArrowBack
          className="absolute top-4 left-1 w-10 sm:w-14 md:w-16 h-8  text-md cursor-pointer"
          style={{ fill: "black" }}
        />
        <div className="w-full h-full flex justify-center items-center ">
          <img src={AiIcon} alt="AI-Icon" className="h-full object-contain"></img>
        </div>
      </div>

      <h1 className="heading-1">Artificiell Intelligens</h1>
      <p>
        <span className="tag">AI</span> står för Artificiell Intelligens och betyder konstgjord intelligens. I praktiken
        är en AI ett datorprogram som är programmerat för att efterlikna hur en mänsklig hjärna fungerar. Mänskliga
        hjärnor består av hjärnceller (neuroner) som är sammanflätade i ett stort och komplext nätverk. En AI har olika
        noder i stället för hjärnceller som är sammanlänkade i djupa hierarkier, där av ordet djupt lärande eller djupa
        nätverk.
        <br />
        <br />I den här labben får du bekanta dig med just en sådan AI med ett djupt nätverk förpackat i en app, Duudle
        AI.
      </p>
      <br />

      <div className="m-auto flex justify-center mt-8 ">
        <img
          src={AiIcon}
          className="w-2/3 sm:w-1/3 object-contain"
          alt="AI-Icon"
          onClick={() => {
            fullsceen.current.classList.toggle("hidden");
          }}></img>
      </div>
      <div className="w-full h-24"></div>
    </div>
  );
};
export default AiLabStep0;
