import { useEffect, useState } from "react";
import { motion } from "framer-motion";
type ProgresBarProps = {
  step: number;
  total: number;
};
// Visual limitation of totalnumber is 13
function ProgressBar(props: ProgresBarProps) {
  const { total, step } = props;
  const [progress, setProgress] = useState([]);

  useEffect(() => {
    let prog = [];
    for (let i = 0; i < total; i++) {
      if (i < step) {
        prog.push(<div key={i} className="rounded-full h-4 w-4 bg-white my-2 mx-1 sm:mx-2" />);
      } else {
        prog.push(<div key={i} className="rounded-full h-4 w-4 border-2 border-white my-2 mx-1 sm:mx-2" />);
      }
    }
    setProgress(prog);
  }, [total, step]);

  return (
    <motion.div className="w-full flex justify-center">
      <div className=" flex flex-col justify-center ">
        <div className="mx-1 sm:mx-2 text-center text-sm sm:text-base font-semibold tracking-widest text-white ">
          Steg&nbsp;{step}&nbsp;av&nbsp;{total}
        </div>
        <div className="flex flex-row h-8">
          {progress.map((dot) => {
            return dot;
          })}
        </div>
      </div>
    </motion.div>
  );
}

export default ProgressBar;
