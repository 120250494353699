import { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import CheckboxText from "../../components/CheckboxText";
import { getCompletedStep, setCompletedStep } from "../../utils/localstorage";

const MpegLabStep4 = () => {
  const history = useHistory();
  let { labId, labStep } = useParams<any>();
  const step = parseInt(labStep);
  const maxPart = 2;
  const [part, setPart] = useState(0);

  function nextPart(nextPart: number) {
    //Handel next Part
    if (nextPart > part) {
      setPart(nextPart);
      if (nextPart === maxPart) {
        setCompletedStep(labId, labStep);
      }
    }
  }
  useEffect(() => {
    //Set boxes checked if step all ready done.
    if (getCompletedStep(labId) >= labStep) {
      setPart(maxPart);
    }
  }, []);
  return (
    <div className="w-full h-full" style={{ maxWidth: 640 }}>
      <h1 className="heading-1">Frågor</h1>
      <p>
        Numera använder vi dagligen inte bara streamingtjänster för att titta på film eller digitala TV-sändningar. Vi
        skapar också egen video.
        <br />
        <br />
      </p>
      <div className="p">
        <CheckboxText
          text={
            " Spelar du in video på din mobil? Hur? Postar du dina video på sociala medier eller delar dem på andra sätt? Hur?"
          }
          defultChecked={part > 0}
          nextPart={() => {
            nextPart(1);
          }}
        />
        <br />
        {/* Part 1 */}
        {part >= 1 && (
          <>
            <CheckboxText
              text={
                "Använder du digital video på andra sätt? Säkert gör du det utan att ens tänka på det? I vilka andra appar är video centrala?"
              }
              defultChecked={part > 1}
              nextPart={() => {
                nextPart(2);
              }}
            />
            <br />
          </>
        )}
        <br />
      </div>
      {/* Part 2 */}
      {part >= maxPart && (
        <p>
          <span
            className="tag text-white cursor-pointer"
            onClick={() => {
              history.push(`/${labId}/${step + 1}`);
            }}>
            fortsätt
          </span>
        </p>
      )}
      <div className="w-full h-24"></div>
    </div>
  );
};

export default MpegLabStep4;
