import { Lab, LabTheme } from "../../model/lab";
import { categories } from "../../utils/global";
import aiIcon from "../../resources/svg/icons/AI-pos.svg";
import aiBackgroundImage from "../../resources/img/backgrounds/Duudle-AI-1.1.jpg";
import aiBackdropImage from "../../resources/img/backdrops/Abstarct-Noise-0.1.jpg";
import { ReactComponent as aiNavigationBackground } from "../../resources/img/navigation/Nav-AI.svg";
import { ReactComponent as aiNavigationNext } from "../../resources/img/navigation/Nav-AI-Next.svg";
import AiLabStep0 from "./AiLabStep0";
import AiLabStep1 from "./AiLabStep1";
import AiLabStep2 from "./AiLabStep2";
import AiLabStep3 from "./AiLabStep3";
import AiLabStep4 from "./AiLabStep4";
import AiLabStep5 from "./AiLabStep5";
import AiLabStep6 from "./AiLabStep6";
import AiLabStep7 from "./AiLabStep7";

var preloadBackgroundImage = new Image();
preloadBackgroundImage.src = aiBackgroundImage;
var preloadBackDropImage = new Image();
preloadBackDropImage.src = aiBackdropImage;

const aiLab = new Lab(
  "ai-lab",
  "AI",
  8,
  12,
  [categories.PRACTICAL, categories.APP],
  { title: "AI", description: "Artificiell Intelligens" },
  { title: "Labb", description1: "Artifical Intelligence", description2: "Artificiell Intelligens" },
  [AiLabStep0, AiLabStep1, AiLabStep2, AiLabStep3, AiLabStep4, AiLabStep5, AiLabStep6, AiLabStep7],
  true,
);

const aiTheme = new LabTheme(
  "#7ce2f2D9",
  aiBackgroundImage,
  aiBackdropImage,
  aiIcon,
  "linear-gradient(0deg, #9a0385, #e44847)",
  "white",
  "#7ce2f2",
  { backgroundUrl: aiNavigationBackground, prevUrl: null, nextUrl: aiNavigationNext },
);
aiLab.setTheme(aiTheme);

export default aiLab;
