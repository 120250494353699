import { useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import { setCompletedStep } from "../../utils/localstorage";

const ArLabStep2 = () => {
  let { labId, labStep } = useParams<any>();
  const history = useHistory();
  const step = parseInt(labStep);
  useEffect(() => {
    // Step is completed right away
    setCompletedStep(labId, labStep);
  }, []);
  return (
    <div className="w-full" style={{ maxWidth: 500 }}>
      <h1 className="heading-1">Obs!</h1>
      <p>
        <span className="tag"> Innan vi börjar</span> AR i webbläsaren är inte helt perfekt ännu. Om du skulle stöta på
        problem har vi några tips:
        <br />
        <br />
        <div className="flex ">
          <div className="mr-6 text-white">■</div>
          <div>Se till att vara i ett ljust rum. Ju ljusare desto bättre fungerar kameran.</div>
        </div>
        <br />
        <div className="flex ">
          <div className="mr-6 text-white">■</div>
          <div>
            Se till att använda en så modern och kraftfull mobiltelefon som möjligt. Äldre mobiltelefoner med sämre
            kamera har "sämre syn" helt enkelt, och "tänker" lite långsammare.
          </div>
        </div>
        <br />
        <div className="flex ">
          <div className="mr-6 text-white">■</div>
          <div>Det kan också hjälpa att låta kameran bara titta på ett kort i taget.</div>
        </div>
        <br />
        <br />
        Gå vidare till nästa steg för att börja uppleva AR.
        <br />
        <br />
        <span
          className="tag text-white cursor-pointer"
          onClick={() => {
            history.push(`/${labId}/${step + 1}`);
          }}>
          fortsätt
        </span>
      </p>
      <div className="w-full h-24"></div>
    </div>
  );
};

export default ArLabStep2;
