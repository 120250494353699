import Tag from "./Tag";
import { Lab } from "../model/lab";
import { useHistory } from "react-router-dom";
import { motion } from "framer-motion";

type LabCardProps = {
  lab: Lab;
};

function LabCard(props: LabCardProps) {
  const { lab } = props;
  const history = useHistory();
  return (
    <>
      <motion.div
        variants={{
          hidden: { opacity: 0, y: 10 },
          show: { opacity: 1, y: 0 },
        }}
        layoutId={lab.id}
        className="relative bg-transparent border-4 rounded-3xl
        m-2  cursor-pointer card"
        style={{
          maxWidth: "250px",
          maxHeight: "250px",
          minWidth: "144px",
          minHeight: "144px",
          backgroundImage: `url(${lab.theme.backgroundUrl})`,
          backgroundPosition: "center",
          backgroundSize: "cover",
          borderColor: lab.theme.homeCardBorderColor,
        }}
        onClick={() => {
          history.push("/" + lab.id);
        }}>
        <div className="sm:pt-1 flex flex-row flex-wrap" style={{ margin: "6px" }}>
          {lab.categories.map((c, i) => {
            return <Tag key={i} text={c} color={"white"} />;
          })}
        </div>
        {/* Information border */}
        <div
          className="absolute -bottom-1 -left-1 -right-1 flex flex-col items-start"
          style={{
            width: "calc(100% + 8px)",
            borderBottomRightRadius: "1.5rem",
            borderBottomLeftRadius: "1.5rem",
            backgroundColor: lab.theme.backgroundColor,
          }}>
          <div className="flex flex-row items-center">
            <img
              className="h-12 w-12 sm:h-14 sm:w-14 mx-1 flex-none"
              src={lab.theme.iconUrl}
              alt={lab.navbarTitle}></img>
            <div className="flex flex-col items-start py-2 text-xs ml-1">
              <span className="tag text-xs " style={{ fontSize: " 1.25rem" }}>
                {lab.homeCard.title}
              </span>
              <div className="text-sm sm:text-base sm:tracking-wider">{lab.homeCard.description}</div>
              <div className="text-xs sm:text-sm tracking-widest font-semibold uppercase"> {lab.time} min</div>
            </div>
          </div>
        </div>
      </motion.div>
    </>
  );
}

export default LabCard;
