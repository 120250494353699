import { useHistory, useParams } from "react-router-dom";
import Button from "../../components/Button";
import { resetSteps } from "../../utils/localstorage";

const MpegLabStep9 = () => {
  const history = useHistory();
  let { labId } = useParams<any>();
  return (
    <div className="w-full h-full" style={{ maxWidth: 640 }}>
      <h1 className="heading-1">Film &amp; spel</h1>
      <p>
        <span className="tag">Idag </span> använder vi datorer när vi gör film både för att klippa den men också för att
        införliva datorgenererade element såsom specialeffekter, som läggs till i nästan allt.
        <br />
        <br />
        Science Fiction är en genre som du inte skulle känna igen om digital video inte fanns, med filmer som Matrix,
        Avatar och Star Wars. Animerad film var från början gjord med kamera där varje filmruta var ett foto av en
        handritad teckning. Numera är de helt digitalt gjorda. <br />
        <br />
        Och allt det här möjliggjordes av innovationer som gjordes på Linköpings universitet och som ledde till{" "}
        <span className="abbr">MPEG</span>.
        <br />
        <br />
      </p>
      <br />
      <br />
      <div className="w-full flex justify-center">
        <Button
          text={"klar"}
          onClick={() => {
            resetSteps(labId);
            history.push(`/`);
          }}
        />
      </div>
      <div className="w-full h-24"></div>
    </div>
  );
};

export default MpegLabStep9;
