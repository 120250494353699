import { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import CheckboxText from "../../components/CheckboxText";
import { getCompletedStep, setCompletedStep } from "../../utils/localstorage";
const MpegLabStep6 = () => {
  const history = useHistory();
  let { labId, labStep } = useParams<any>();
  const step = parseInt(labStep);
  const maxPart = 1;
  const [part, setPart] = useState(0);

  function nextPart(nextPart: number) {
    //Handel next Part
    if (nextPart > part) {
      setPart(nextPart);
      if (nextPart === maxPart) {
        setCompletedStep(labId, labStep);
      }
    }
  }
  useEffect(() => {
    //Set boxes checked if step all ready done.
    if (getCompletedStep(labId) >= labStep) {
      setPart(maxPart);
    }
  }, []);
  return (
    <div className="w-full h-full" style={{ maxWidth: 640 }}>
      <h1 className="heading-1">Utan video</h1>
      <p>
        I nuläget finns videon överallt. På Internet, bland dina meddelanden, TV, digitala annonsskämar etc.
        <br />
        <br />
      </p>
      <div className="p">
        <CheckboxText
          text={"Tänk bort video? Hur skulle din dag se ut utan video?"}
          defultChecked={part > 0}
          nextPart={() => {
            nextPart(1);
          }}
        />
        <br />
      </div>
      {/* Part 1 */}
      {part >= 1 && (
        <p>
          Visst har livet blivit mer innehållsrikt, och tack vare en innovation från Linköpings universitet och Sverige.
          <br />
          <br />
          <span
            className="tag text-white cursor-pointer"
            onClick={() => {
              history.push(`/${labId}/${step + 1}`);
            }}>
            fortsätt
          </span>
        </p>
      )}
      <div className="w-full h-24"></div>
    </div>
  );
};

export default MpegLabStep6;
