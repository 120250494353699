import React from "react";

export class Lab {
  // Public
  id: string;
  navbarTitle: string;
  totalSteps: number;
  time: number;
  categories: Array<string>;
  theme: LabTheme;
  homeCard: { title: string; description: string };
  startCard: { title: string; description1: string; description2: string };
  steps: React.FunctionComponent[];
  forceCompleteEachStep: boolean;

  constructor(
    id: string,
    navbarTitle: string,
    totalSteps: number,
    time: number,
    categories: Array<string>,
    homeCard: { title: string; description: string },
    startCard: { title: string; description1: string; description2: string },
    steps: React.FunctionComponent[],
    forceCompleteEachStep: boolean, // See Note in the bottom
  ) {
    this.id = id;
    this.navbarTitle = navbarTitle;
    this.totalSteps = totalSteps;
    this.time = time;
    this.categories = categories;
    this.homeCard = homeCard;
    this.startCard = startCard;
    this.steps = steps;
    this.forceCompleteEachStep = forceCompleteEachStep;
  }
  setTheme(theme: LabTheme) {
    this.theme = theme;
  }
  getStep(step: number) {
    if (!this.steps[step - 1]) {
      // Return "React functional component" rendering nothing
      return () => {
        return null;
      };
    }
    return this.steps[step - 1];
  }
}

export class LabTheme {
  backgroundColor: string;
  backgroundUrl: string;
  backdropUrl: string;
  iconUrl: string;
  gradientBackground: string;
  tagColor: string;
  homeCardBorderColor: string;
  navigation: {
    backgroundUrl: React.FunctionComponent;
    prevUrl: React.FunctionComponent;
    nextUrl: React.FunctionComponent;
  };

  constructor(
    backgroundColor: string,
    backgroundUrl: string,
    backdropUrl: string,
    iconUrl: string,
    gradientBackground: string,
    tagColor: string,
    homeCardBorderColor: string,
    navigation: {
      backgroundUrl: React.FunctionComponent;
      prevUrl: React.FunctionComponent;
      nextUrl: React.FunctionComponent;
    },
  ) {
    this.backgroundColor = backgroundColor;
    this.backgroundUrl = backgroundUrl;
    this.backdropUrl = backdropUrl;
    this.iconUrl = iconUrl;
    this.gradientBackground = gradientBackground;
    this.tagColor = tagColor;
    this.homeCardBorderColor = homeCardBorderColor;
    this.navigation = navigation;
  }
  getNavigationBackground() {
    if (!this.navigation.backgroundUrl) {
      // Return "React functional component" rendering nothing
      return () => {
        return null;
      };
    }
    return this.navigation.backgroundUrl;
  }
  getNavigationPrev() {
    if (!this.navigation.prevUrl) {
      // Return "React functional component" rendering nothing
      return () => {
        return null;
      };
    }
    return this.navigation.prevUrl;
  }
  getNavigationNext() {
    if (!this.navigation.nextUrl) {
      // Return "React functional component" rendering nothing
      return () => {
        return null;
      };
    }
    return this.navigation.nextUrl;
  }
}

// Note - forceCompleteEachStep
// forceCompleteEachStep = true means that the user has to finish a step before moving to the next
// If a step is completed is decided in each lab step
// The progress made in each lab is saved to localstorage (see src/utils/localstorage.ts)
// Navigation.tsx listens to changes in localstorage and enable/disable the next button accordingly
