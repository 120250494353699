import { useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import { setCompletedStep } from "../../utils/localstorage";

const ArLabStep1 = () => {
  let { labId, labStep } = useParams<any>();
  const history = useHistory();
  const step = parseInt(labStep);
  useEffect(() => {
    // Step is completed right away
    setCompletedStep(labId, labStep);
  }, []);
  return (
    <div className="w-full" style={{ maxWidth: 500 }}>
      <h1 className="heading-1">AR</h1>
      <p>
        <span className="tag">AR </span>
        står för Augmented Reality och betyder förstärkt verklighet på svenska. Det betyder att den riktiga världen
        förstärks med datorskapat innehåll.
        <br />
        <br />
        Du ska nu få testa AR med mobilen du håller i din hand.
        <br />
        <br />
        <span
          className="tag text-white cursor-pointer"
          onClick={() => {
            history.push(`/${labId}/${step + 1}`);
          }}>
          fortsätt
        </span>
      </p>
      <div className="w-full h-24"></div>
    </div>
  );
};

export default ArLabStep1;
