import Duudle from "./../../resources/img/d-Icon-round-hr-512x512px.png";
import AppStore from "./../../resources/img/App-Store_Sprite-Atlas.svg";
import { useEffect, useState } from "react";
import { setCompletedStep } from "../../utils/localstorage";
import { useParams } from "react-router-dom";

const AiLabStep1 = () => {
  const [android, setAndroid] = useState(true);
  const [ios, setIos] = useState(true);

  useEffect(() => {
    if (navigator.userAgent.toLowerCase().indexOf("android") > -1) {
      setAndroid(true);
      setIos(false);
    } else if (
      navigator.userAgent.toLowerCase().indexOf("iphone") > -1 ||
      navigator.userAgent.toLowerCase().indexOf("mac") > -1
    ) {
      setAndroid(false);
      setIos(true);
    }
  }, []);
  let { labId, labStep } = useParams<any>();
  useEffect(() => {
    // Step is completed right away
    setCompletedStep(labId, labStep);
  }, []);
  return (
    <div className="w-full h-full" style={{ maxWidth: 640 }}>
      <h1 className="heading-1">Duudle AI</h1>
      <p>
        <span className="tag text-white">app. </span>
        Labben kräver en app.
        <br />
        <br />
        <span className="tag">Ladda ned </span>
        appen{" "}
        <a
          className="a-tag"
          href={
            ios
              ? "https://apps.apple.com/app/duudle-ai-fun-drawing-game/id1501674180"
              : "https://play.google.com/store/apps/details?id=com.visiarc.duudleai"
          }
          target={"_blank"}>
          Duudle AI
        </a>{" "}
        och installera på din mobil eller surfplatta. Du behöver alltså den här mobilen för handledningen, och en
        ytterligare mobil eller surfplatta för appen.
      </p>
      <div
        className="m-auto bg-center my-8 "
        style={{
          backgroundImage: `url(${Duudle})`,
          backgroundSize: "100%",
          height: "150px",
          width: "150px",
        }}></div>
      <div className="flex flex-row justify-center flex-wrap ">
        {ios && (
          <a
            href="https://apps.apple.com/app/duudle-ai-fun-drawing-game/id1501674180"
            target={"_blank"}
            aria-label="Ladda ner Duudle från IOS">
            <div
              className="m-auto transform transition-all hover:scale-95 hover:opacity-80"
              style={{
                backgroundImage: `url(${AppStore})`,
                backgroundSize: "400%",
                backgroundRepeat: "no-repeat",
                width: "244px",
                height: "88px",
                backgroundPosition: "-732px 0",
              }}></div>
          </a>
        )}
        {android && (
          <a
            href="https://play.google.com/store/apps/details?id=com.visiarc.duudleai"
            target={"_blank"}
            aria-label="Ladda ner Duudle från Google Play">
            <div
              className="m-auto transform transition-all hover:scale-95 hover:opacity-80"
              style={{
                backgroundImage: `url(${AppStore})`,
                backgroundSize: "400%",
                backgroundRepeat: "no-repeat",
                width: "244px",
                height: "88px",
                backgroundPosition: "-244px 0",
              }}></div>
          </a>
        )}
      </div>
      <div className="w-full h-24"></div>
    </div>
  );
};
export default AiLabStep1;
