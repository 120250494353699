import { Lab, LabTheme } from "../../model/lab";
import { categories } from "../../utils/global";
import mpegIcon from "../../resources/svg/icons/Streaming-Media-pos.svg";
import mpegBackgroundImage from "../../resources/img/backgrounds/02-digitalization.jpg";
import mpegBackDropImage from "../../resources/img/backdrops/Peak-Noise-0.1.jpg";
import { ReactComponent as mpegNavigationPrev } from "../../resources/img/navigation/Nav-MPEG-Prev.svg";
import { ReactComponent as mpegNavigationNext } from "../../resources/img/navigation/Nav-Next.svg";
import MpegLabStep1 from "./MpegLabStep1";
import MpegLabStep2 from "./MpegLabStep2";
import MpegLabStep3 from "./MpegLabStep3";
import MpegLabStep4 from "./MpegLabStep4";
import MpegLabStep5 from "./MpegLabStep5";
import MpegLabStep6 from "./MpegLabStep6";
import MpegLabStep7 from "./MpegLabStep7";
import MpegLabStep8 from "./MpegLabStep8";
import MpegLabStep9 from "./MpegLabStep9";

var preloadBackgroundImage = new Image();
preloadBackgroundImage.src = mpegBackgroundImage;
var preloadBackDropImage = new Image();
preloadBackDropImage.src = mpegBackDropImage;

const mpegLab = new Lab(
  "mpeg-lab",
  "MPEG",
  9,
  7,
  [categories.THEORETICAL],
  { title: "MPEG", description: "Digital video" },
  { title: "Labb", description1: "Digital Video — MPEG", description2: "Streaming Media (Strömmad media)" },
  [
    MpegLabStep1,
    MpegLabStep2,
    MpegLabStep3,
    MpegLabStep4,
    MpegLabStep5,
    MpegLabStep6,
    MpegLabStep7,
    MpegLabStep8,
    MpegLabStep9,
  ],
  true,
);

const mpegTheme = new LabTheme(
  "#ffffff99",
  mpegBackgroundImage,
  mpegBackDropImage,
  mpegIcon,
  "linear-gradient(0deg, #0e57e5, #11b2e2)",
  "white",
  "#f34497",
  { backgroundUrl: null, prevUrl: mpegNavigationPrev, nextUrl: mpegNavigationNext },
);
mpegLab.setTheme(mpegTheme);

export default mpegLab;
