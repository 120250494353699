import { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import CheckboxText from "../../components/CheckboxText";
import { getCompletedStep, setCompletedStep } from "../../utils/localstorage";

const ArLabStep7 = () => {
  let { labId, labStep } = useParams<any>();
  const history = useHistory();
  const step = parseInt(labStep);
  const maxPart = 1;
  const [part, setPart] = useState(0);

  function nextPart(nextPart: number) {
    //Handel next Part
    if (nextPart > part) {
      setPart(nextPart);
      if (nextPart === maxPart) {
        setCompletedStep(labId, labStep);
      }
    }
  }
  useEffect(() => {
    //Set boxes checked if step all ready done.
    if (getCompletedStep(labId) >= labStep) {
      setPart(maxPart);
    }
  }, []);
  return (
    <div className="w-full" style={{ maxWidth: 500 }}>
      <h1 className="heading-1 ">Resultat</h1>
      <p>Du har hittat fyra bokstäver. Tillsammans bildar de forkortningen:</p>
      <div className="text-center">
        <h2 className="tag">MPEG</h2>
      </div>
      <br />
      <p>
        <CheckboxText
          text={
            <p>
              Kanske har du hört talas om <span className="abbr">MPEG</span>. Vet du vad{" "}
              <span className="abbr">MPEG</span> står för?
            </p>
          }
          defultChecked={part > 0}
          nextPart={() => {
            nextPart(1);
          }}
        />
      </p>
      <br />
      {/* Part 1 */}
      {part >= maxPart && (
        <span
          className="tag text-white cursor-pointer"
          onClick={() => {
            history.push(`/${labId}/${step + 1}`);
          }}>
          fortsätt
        </span>
      )}
      <div className="w-full h-24"></div>
    </div>
  );
};

export default ArLabStep7;
