import { useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import { setCompletedStep } from "../../utils/localstorage";
const MpegLabStep1 = () => {
  const history = useHistory();
  let { labId, labStep } = useParams<any>();
  const step = parseInt(labStep);
  useEffect(() => {
    // Step is completed right away
    setCompletedStep(labId, labStep);
  }, []);
  return (
    <div className="w-full h-full" style={{ maxWidth: 640 }}>
      <h1 className="heading-1">MPEG</h1>
      <p>
        <span className="tag">MPEG </span> står för Moving Pictures Expert Group, som på svenska betyder expertgruppen
        för rörliga bilder. Vi pratar om rörliga digitala bilder, alltså video, digital video.
        <br />
        <br />
        Grunden för <span className="abbr">MPEG</span> och rörliga digitala bilder är en innovation från Linköping.
        Du använder det säkert varje dag?
      </p>

      <p>
        <br />
        <span
          className="tag text-white cursor-pointer"
          onClick={() => {
            history.push(`/${labId}/${step + 1}`);
          }}>
          fortsätt
        </span>
      </p>
      <div className="w-full h-24"></div>
    </div>
  );
};

export default MpegLabStep1;
