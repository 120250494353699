import { useHistory, useParams } from "react-router-dom";
import Button from "../../components/Button";
import { resetSteps } from "../../utils/localstorage";

const MobiltelefonLabStep6 = () => {
  const history = useHistory();
  let { labId } = useParams<any>();
  return (
    <div className="w-full h-full" style={{ maxWidth: 640 }}>
      <h1 className="heading-1">Mobilen</h1>
      <p>
        <span className="tag">Mobilen </span>
        har förändrat hur vi lever. Med global täckning är vi alla nåbara oavsett var vi är i världen. Med mobildata
        (surf) har sätten vi lever våra liv stöpts om, med omedelbar tillgång till information, kommunikation och
        underhållning.
        <br />
        <br />I Sverige föddes mobiltelefonin. Hos Ericsson med sin mobilbas i Linköping Science Park gjordes
        innovationerna och utvecklades tekniken.
      </p>
      <br />
      <br />
      <div className="w-full flex justify-center">
        <Button
          text={"klar"}
          onClick={() => {
            resetSteps(labId);
            history.push(`/`);
          }}
        />
      </div>
      <div className="w-full h-24"></div>
    </div>
  );
};

export default MobiltelefonLabStep6;
