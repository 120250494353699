import { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import CheckboxText from "../../components/CheckboxText";
import { getCompletedStep, setCompletedStep } from "../../utils/localstorage";
const MobiltelefonLabStep3 = () => {
  const history = useHistory();
  let { labId, labStep } = useParams<any>();
  const step = parseInt(labStep);
  const maxPart = 2;
  const [part, setPart] = useState(0);

  function nextPart(nextPart: number) {
    //Handel next Part
    if (nextPart > part) {
      setPart(nextPart);
      if (nextPart === maxPart) {
        setCompletedStep(labId, labStep);
      }
    }
  }
  useEffect(() => {
    //Set boxes checked if step all ready done.
    if (getCompletedStep(labId) >= labStep) {
      setPart(maxPart);
    }
  }, []);
  return (
    <div className="w-full h-full" style={{ maxWidth: 640 }}>
      <h1 className="heading-1">Utan mobil </h1>
      <p>
        <span className="tag">Dina föräldrar </span> växte upp i en tid utan mobiltelefoni. Världen fungerade ändå, men
        hur? De gjorde samma saker som du, men annorlunda. Om ingen har mobil, hur gör man då?
        <br />
        <br />
      </p>
      <div className="p">
        <CheckboxText
          text={"Hur berättar du att du blir sen? Något har hänt, hur får du tag på hjälp?"}
          defultChecked={part > 0}
          nextPart={() => {
            nextPart(1);
          }}
        />
        {/* Part 1 */}
        {part >= 1 && (
          <>
            <br />
            Du kan ringa så klart, med fast telefon (med sladd).
            <br />
            <br />
            <CheckboxText
              text={"Har du ringt i en fast telefon? Skulle du komma ihåg numret till dina föräldrar?"}
              defultChecked={part > 1}
              nextPart={() => {
                nextPart(2);
              }}
            />
            <br />
          </>
        )}
      </div>
      {/* Part 2 */}
      {part >= maxPart && (
        <p>
          Dina föräldrar kunde alla viktiga telefonnummer i huvudet. Alla nummer man behövde skrev man ner i en
          telefonbok. Alla hade en telefonbok i papper.
          <br />
          <br />
          <span
            className="tag text-white cursor-pointer"
            onClick={() => {
              history.push(`/${labId}/${step + 1}`);
            }}>
            fortsätt
          </span>
        </p>
      )}
      <div className="w-full h-24"></div>
    </div>
  );
};

export default MobiltelefonLabStep3;
