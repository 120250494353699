type TagProps = {
  text: String;
  onClick?: React.MouseEventHandler<HTMLDivElement>;
  color: string;
  active?: boolean;
  borderColor?: string;
};

function Tag(props: TagProps) {
  const { text, onClick, color, active, borderColor } = props;
  return (
    <>
      <div
        className="mr-1 sm:mx-1 mb-1 px-1 sm:px-2 md:px-1 lg:px-2 font-semibold bg-transparent border-2 rounded-full flex justify-center items-center tracking-wider text-xs sm:text-sm lg:text-base text-center"
        style={{
          width: "fit-content",
          paddingTop: "2px",
          paddingBottom: "2px",
          border: "1 px solid ",
          borderColor: borderColor,
          backgroundColor: active ? borderColor : "transparent",
          cursor: onClick ? "pointer" : "default",
          color: color,
        }}
        onClick={onClick}>
        {text}
      </div>
    </>
  );
}

export default Tag;
