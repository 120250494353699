import { useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import { setCompletedStep } from "../../utils/localstorage";
const MpegLabStep8 = () => {
  const history = useHistory();
  let { labId, labStep } = useParams<any>();
  const step = parseInt(labStep);
  useEffect(() => {
    // Step is completed right away
    setCompletedStep(labId, labStep);
  }, []);
  return (
    <div className="w-full h-full" style={{ maxWidth: 640 }}>
      <h1 className="heading-1">Utan video</h1>
      <p>
        Utan digital video:
        <br />
        <br />
      </p>
      <div className="p">
        <div className="flex ">
          <div className="mr-6 text-white">■</div>
          <div>
            <span className="tag">Skicka eller posta videomeddelande. </span> Inte möjligt. Du får ringa eller skicka
            textmeddelanden. Borta är TikTok och Snapchat. I en annan labb får du lära dig mer om mobiltelefoni.
          </div>
        </div>
        <br />
        <div className="flex ">
          <div className="mr-6 text-white">■</div>
          <div>
            <span className="tag">Distansmöte. </span>Nej, du får åka och träffas om du vill ses.
          </div>
        </div>
        <br />
      </div>
      <p>
        <span
          className="tag text-white cursor-pointer"
          onClick={() => {
            history.push(`/${labId}/${step + 1}`);
          }}>
          fortsätt
        </span>
      </p>
      <div className="w-full h-24"></div>
    </div>
  );
};

export default MpegLabStep8;
