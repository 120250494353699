import { useEffect, useState } from "react";

type CheckboxTextProps = {
  text: String | JSX.Element;
  nextPart: Function;
  defultChecked: boolean;
};

function CheckboxText(props: CheckboxTextProps) {
  let { text, nextPart, defultChecked } = props;
  const [checked, setChecked] = useState(defultChecked);

  useEffect(() => {
    setChecked(defultChecked);
  }, [defultChecked]);

  function changeChecked() {
    setChecked(!checked);
    nextPart();
  }
  return (
    <>
      <div className="mt-2 flex cursor-pointer" onClick={changeChecked}>
        <div className=" mr-3 -mt-6" style={{ marginTop: "-23px" }}>
          {!checked ? (
            <div
              className=" w-6 h-6 font-visiarc-icon text-left text-xl text-white animate-bounce"
              style={{ marginLeft: "3px" }}>
              {"\ue064"}
            </div>
          ) : (
            <div className=" w-6 h-6 " style={{ marginLeft: "3px" }}></div>
          )}
          <input
            className="w-4 h-4 cursor-pointer"
            style={{ borderColor: !checked ? "#f34497" : "white" }}
            type="checkbox"
            checked={checked}
            onChange={changeChecked}
          />
        </div>
        <div onClick={changeChecked}>{text}</div>
      </div>
    </>
  );
}

export default CheckboxText;
