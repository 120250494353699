type ButtonProps = {
  text: String;
  onClick: React.MouseEventHandler<HTMLDivElement>;
};

function Button(props: ButtonProps) {
  const { text, onClick } = props;
  return (
    <>
      <div
        className="bg-linkping-r243g68b151 rounded-full px-8 py-4 
        flex justify-center items-center
        text-2xl text-center text-white font-bold cursor-pointer transform transition-all hover:scale-95"
        style={{ minWidth: "160px", letterSpacing: "0.1em", width: "fit-content", fontVariant: "all-small-caps" }}
        onClick={onClick}>
        {text}
      </div>
    </>
  );
}

export default Button;
