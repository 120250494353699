import { useEffect, useRef, useState } from "react";
import { IoMdArrowBack } from "react-icons/io";
import { useHistory, useParams } from "react-router-dom";
import CheckboxText from "../../components/CheckboxText";
import { getCompletedStep, setCompletedStep } from "../../utils/localstorage";
import Duudle from "./../../resources/img/How-to-play-DuudleAI.jpg";

const AiLabStep2 = () => {
  const history = useHistory();
  let { labId, labStep } = useParams<any>();
  const step = parseInt(labStep);
  const fullsceen = useRef(null);
  const maxPart = 1;
  const [part, setPart] = useState(0);

  function nextPart(nextPart: number) {
    //Handel next Part
    if (nextPart > part) {
      setPart(nextPart);
      if (nextPart === maxPart) {
        setCompletedStep(labId, labStep);
      }
    }
  }
  useEffect(() => {
    //Set boxes checked if step all ready done.
    if (getCompletedStep(labId) >= labStep) {
      setPart(maxPart);
    }
  }, []);
  return (
    <div className="w-full h-full" style={{ maxWidth: 640 }}>
      <div
        ref={fullsceen}
        onClick={() => {
          fullsceen.current.classList.toggle("hidden");
        }}
        className="hidden fixed top-0 left-0 right-0 bottom-0 z-50 bg-black bg-opacity-95">
        <IoMdArrowBack
          className="absolute top-4 left-1 w-10 sm:w-14 md:w-16 h-8  text-md cursor-pointer"
          style={{ fill: "white" }}
        />
        <div className="w-full h-full flex justify-center items-center ">
          <img src={Duudle} alt="Duudle AI - a drawinggame" className="h-full object-contain"></img>
        </div>
      </div>

      <h1 className="heading-1">Starta &amp; spela</h1>
      <p>
        <span className="tag">Appen Duudle AI </span>
        är en <span className="abbr">Rita&ndash;och&ndash;en&ndash;AI&ndash;gissar </span>vad du ritar. Följ
        instruktionerna i appen. Kör en omgång och försök rita varje ord. Du får fem ord. Ju fortare{" "}
        <span className="abbr">AI</span>&#58;n kan gissa vad du ritar desto mer poäng får du.
      </p>
      <br />
      <p>
        <CheckboxText
          text={"När du har kört en omgång med fem ord."}
          defultChecked={part > 0}
          nextPart={() => {
            nextPart(1);
          }}
        />
        <br />
        <br />
        {/* Part 1 */}
        {part >= maxPart && (
          <span
            className="tag text-white cursor-pointer"
            onClick={() => {
              history.push(`/${labId}/${step + 1}`);
            }}>
            fortsätt
          </span>
        )}
      </p>
      <div className="m-auto flex justify-center mt-8 ">
        <img
          src={Duudle}
          className="w-2/3 sm:w-1/3 object-contain"
          alt="Duudle AI - a drawinggame"
          onClick={() => {
            fullsceen.current.classList.toggle("hidden");
          }}></img>
      </div>
      <div className="w-full h-24"></div>
    </div>
  );
};
export default AiLabStep2;
