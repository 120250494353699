import { Lab, LabTheme } from "../../model/lab";
import { categories } from "../../utils/global";
import arIcon from "../../resources/svg/icons/AR-pos.svg";
import arBackgroundImage from "../../resources/img/backdrops/Lava-Noise-0.1.jpg";
import arBackDropImage from "../../resources/img/backgrounds/AR-Cards-dk-2-0.1.jpg";
import { ReactComponent as arNavigationBackground } from "../../resources/img/navigation/Nav-AR.svg";
import { ReactComponent as arNavigationNext } from "../../resources/img/navigation/Nav-AR-Next.svg";
import ArLabStep0 from "./ArLabStep0";
import ArLabStep1 from "./ArLabStep1";
import ArLabStep2 from "./ArLabStep2";
import ArLabStep3 from "./ArLabStep3";
import ArLabStep4 from "./ArLabStep4";
import ArLabStep5 from "./ArLabStep5";
import ArLabStep6 from "./ArLabStep6";
import ArLabStep7 from "./ArLabStep7";
import ArLabStep8 from "./ArLabStep8";
import ArLabStep9 from "./ArLabStep9";
import ArLabStep10 from "./ArLabStep10";
import ArLabStep11 from "./ArLabStep11";

var preloadBackgroundImage = new Image();
preloadBackgroundImage.src = arBackgroundImage;
var preloadBackDropImage = new Image();
preloadBackDropImage.src = arBackDropImage;
const arLab = new Lab(
  "ar-lab",
  "AR",
  12,
  8,
  [categories.PRACTICAL, categories.LAB_KIT],
  { title: "AR", description: "Augmented Reality" },
  { title: "Labb", description1: "Augmented Reality", description2: "Förstärkt verklighet" },
  [
    ArLabStep0,
    ArLabStep1,
    ArLabStep2,
    ArLabStep3,
    ArLabStep4,
    ArLabStep5,
    ArLabStep6,
    ArLabStep7,
    ArLabStep8,
    ArLabStep9,
    ArLabStep10,
    ArLabStep11,
  ],
  true,
);

const arTheme = new LabTheme(
  "#ffffffD9",
  arBackDropImage,
  arBackgroundImage,
  arIcon,
  "linear-gradient(0deg, #9b0489, #e43995)",
  "black",
  "#f27d6b",
  { backgroundUrl: arNavigationBackground, prevUrl: null, nextUrl: arNavigationNext },
);
arLab.setTheme(arTheme);

export default arLab;
