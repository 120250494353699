import { useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import { setCompletedStep } from "../../utils/localstorage";
import duudleaiCanvasBackground from "../../resources/img/canvas_blue_tile.jpg";
const AiLabStep6 = () => {
  const history = useHistory();
  let { labId, labStep } = useParams<any>();
  const step = parseInt(labStep);
  useEffect(() => {
    // Step is completed right away
    setCompletedStep(labId, labStep);
  }, []);
  return (
    <div className="w-full h-full" style={{ maxWidth: 640 }}>
      <h1 className="heading-1">Under huven</h1>
      <p>
        Hur fungerar en <span className="abbr">AI</span> egentligen? Baserat på vad du ritar är en AI olika säker på
        sitt svar. Tryck nu på ikonen &ldquo;<span className="abbr">AI</span>&rdquo; uppe till vänster. Du får nu se
        vilka olika alternativ
        <span className="abbr"> AI</span>&#58;n överväger. Den visar också i procent hur säker den är. 100&nbsp;%
        &mdash; då är <span className="abbr">AI</span>&#58;n helt säker
        <br />
        <br />I ritläget se om du kan få <span className="abbr">AI</span>&#58;n att bli helt säker, 100&nbsp;%. Roliga
        saker att rita som är lika men olika.
      </p>
      <div className="p">
        <br />
        <div className="flex ">
          <div className="mr-6 text-white">■</div>
          <div>
            <span className="tag">Böjda streck.</span> Leende, banan, måne och kulle. Alla kan ritas med ett eller två
            böjda streck.
          </div>
        </div>
        {/* Bended lines */}
        <div className="flex flex-row w-full justify-evenly mt-6 mb-4">
          <div
            className="h-20 w-20 flex flex-nowrap flex-row justify-center"
            style={{
              backgroundImage: `url(${duudleaiCanvasBackground})`,
              backgroundPosition: "center",
              backgroundSize: "contain",
              border: "solid 2px black",
            }}>
            <div
              className="h-24 w-16 border-2 border-white -mt-11 "
              style={{
                borderColor: " transparent transparent #FFF ",
                borderRadius: " 50% 50%",
              }}
            />
          </div>
          <div
            className="h-20 w-20 flex flex-nowrap flex-col items-center justify-center p-2"
            style={{
              backgroundImage: `url(${duudleaiCanvasBackground})`,
              backgroundPosition: "center",
              backgroundSize: "contain",
              border: "solid 2px black",
            }}>
            <div
              className="h-16 w-24 border-2 border-white ml-16"
              style={{
                borderColor: "transparent transparent transparent #FFF ",
                borderRadius: " 50% 50%",
              }}
            />
          </div>
          <div
            className="h-20 w-20 flex flex-nowrap flex-row justify-center items-center"
            style={{
              backgroundImage: `url(${duudleaiCanvasBackground})`,
              backgroundPosition: "center",
              backgroundSize: "contain",
              border: "solid 2px black",
            }}>
            <div
              className="h-24 w-16 border-2 border-white mt-20"
              style={{
                borderColor: "#FFF transparent transparent ",
                borderRadius: " 50% 50%",
              }}
            />
          </div>
        </div>
        <br />
        <div className="flex ">
          <div className="mr-6 text-white">■</div>
          <div>
            <span className="tag">Två circklar.</span>
            Testa att rita med två cirklar allra först. Cykel. Bil. Snögubbe. Traktor (Bakhulet är större).
          </div>
        </div>
        {/* Circles */}
        <div className="flex flex-row w-full justify-evenly  mt-6 mb-4">
          <div
            className="h-20 w-20 flex flex-nowrap flex-row items-end justify-evenly pb-5"
            style={{
              backgroundImage: `url(${duudleaiCanvasBackground})`,
              backgroundPosition: "center",
              backgroundSize: "contain",
              border: "solid 2px black",
            }}>
            <div className="h-4 w-4 border-2 border-white rounded-full" />
            <div className="h-4 w-4 border-2 border-white rounded-full" />
          </div>
          <div
            className=" h-20 w-20  flex flex-nowrap flex-col items-center justify-center "
            style={{
              backgroundImage: `url(${duudleaiCanvasBackground})`,
              backgroundPosition: "center",
              backgroundSize: "contain",
              border: "solid 2px black",
            }}>
            <div className="h-4 w-4 border-2 border-white rounded-full mb-1" />
            <div className="h-4 w-4 border-2 border-white rounded-full" />
          </div>
          <div
            className=" h-20 w-20 flex flex-nowrap flex-row items-end justify-evenly p-2 pb-5"
            style={{
              backgroundImage: `url(${duudleaiCanvasBackground})`,
              backgroundPosition: "center",
              backgroundSize: "contain",
              border: "solid 2px black",
            }}>
            <div className="h-5 w-5 border-2 border-white rounded-full" />
            <div className="h-3 w-3 border-2 border-white rounded-full" />
          </div>
        </div>
        <br />
      </div>
      <p>
        <span
          className="tag text-white cursor-pointer"
          onClick={() => {
            history.push(`/${labId}/${step + 1}`);
          }}>
          fortsätt
        </span>
      </p>
      <div className="w-full h-24"></div>
    </div>
  );
};
export default AiLabStep6;
