import { useEffect,  useState } from "react";
import { motion } from "framer-motion";

export type AlertProps = {
  action: "EXIT";
  onConfirm: Function;
  onCancel: Function;
};

function AlertPopup(props: AlertProps) {
  const { action, onConfirm, onCancel } = props;
  const [mobile, setMobile] = useState(false);

  useEffect(() => {
    function handleResize() {
      if (window.innerWidth <= 640) {
        setMobile(true);
      } else {
        setMobile(false);
      }
    }
    handleResize();
    window.addEventListener("resize", handleResize);
  }, []);

  let message = "";
  let actionText1 = "";
  let actionText2 = "";
  if (action === "EXIT") {
    actionText1 = "Avsluta labben";
    actionText2 = "Fortsätt labben";
    message = `Är du säker på att du vill avsluta labben?`;
  }

  return (
    <>
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        className="absolute flex justify-center items-center h-screen w-full"
        style={{ backgroundColor: "#00000062", zIndex: 998 }}
        onClick={() => {
          onCancel();
        }}>
        <div
          style={{
            minHeight: "fit-content",
            minWidth: mobile ? "80%" : "fit-content",
            zIndex: 999,
          }}
          className="bg-white relative whitespace-pre-line rounded-xl p-8"
          onClick={(e) => e.stopPropagation()}>
          <div className="font-Source tracking-wide flex flex-col justify-center py-6 ">
            <div className="text-lg text-center break-words black font-bold">{message}</div>
            <div className="flex flex-col sm:flex-row justify-center mt-6  ">
              <div
                className="flex items-center justify-center px-8 py-2 my-2 mx-2
              rounded-full cursor-pointer bg-linkping-r243g68b151
              text-xl text-white font-bold tracking-wider text-center tracking-widesttransform transition-all hover:scale-95"
                style={{ fontVariant: "all-small-caps" }}
                onClick={(e) => {
                  e.stopPropagation();
                  onConfirm();
                }}>
                {actionText1}
              </div>
              <div
                className="flex items-center justify-center px-8 py-2 my-2 mx-2
              rounded-full cursor-pointer bg-linkping-r243g68b151
              text-xl text-white font-bold tracking-wider text-center tracking-widesttransform transition-all hover:scale-95"
                style={{ fontVariant: "all-small-caps" }}
                onClick={(e) => {
                  e.stopPropagation();
                  onCancel();
                }}>
                {actionText2}
              </div>
            </div>
          </div>
        </div>
      </motion.div>
    </>
  );
}

export default AlertPopup;
