import { useHistory } from "react-router-dom";
import { useContext, useEffect, useRef, useState } from "react";
import { AnimatePresence, motion } from "framer-motion";
//SVG
import { ReactComponent as LogoLSP } from "../resources/svg/logotypes/logotype_center_heavy_white_srgb.svg";
import { ReactComponent as LogoMadeInLKPG } from "../resources/svg/logotypes/Made-in-Linkoping-neg.svg";
import { MdOutlineClose } from "react-icons/md";
import { AiOutlineMenu } from "react-icons/ai";
//Components
import Tag from "../components/Tag";
import LabCard from "../components/LabCard";

// Img
import backgroundImg from "../resources/img/backdrops/Abstarct-Noise-0.1.jpg";
import { categories } from "../utils/global";
import { LabContext } from "../utils/context";
function Home() {
  const history = useHistory();
  const labs = useContext(LabContext);
  const labStepContainer = useRef(null);
  const [filter, setFilter] = useState("Alla");
  const [menuOpen, setMenuOpen] = useState(false);
  const menu = {
    open: {
      clipPath: `circle(${150 + "vh"} at 50vw 50vh)`,
      transition: {
        type: "spring",
        stiffness: 20,
        restDelta: 2,
      },
    },
    closed: {
      clipPath: `circle(0px at 100% 0vh)`,
      transition: {
        type: "spring",
        stiffness: 400,
        damping: 40,
      },
    },
  };

  useEffect(() => {
    const handleResize = () => {
      if (labStepContainer && labStepContainer.current) {
        labStepContainer.current.style.height = `calc(${window.innerHeight}px - 68px - 52px - 15%)`;
      }
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  });

  return (
    <>
      <motion.div
        // initial={{ opacity: 0 }}
        // animate={{ opacity: 1 }}
        className="font-Source w-screen h-screen bottom-0 top-0 fixed"
        style={{ maxWidth: "1200px", backgroundImage: `url(${backgroundImg})`, backgroundSize: "cover" }}>
        {/* Menu */}
        <AnimatePresence exitBeforeEnter>
          {menuOpen && (
            <motion.div
              initial={"closed"}
              animate={"open"}
              exit={"closed"}
              className={`w-full h-full fixed bg-c0m35y85k0 font-Source`}
              style={{ zIndex: 3, maxWidth: "1200px" }}
              variants={menu}>
              <div className={`absolute right-2 pt-4 `}>
                <div className="justify-center cursor-pointer order-3">
                  <MdOutlineClose
                    className="w-10 sm:w-14 md:w-16 h-8 text-md cursor-pointer"
                    style={{ fill: "white" }}
                    onClick={() => {
                      setMenuOpen(!menuOpen);
                    }}
                  />
                </div>
              </div>
              <div className="flex justify-center flex-col items-center h-full pb-20">
                <ul className="font-bold text-lg text-white uppercase">
                  <li
                    className="py-4 cursor-pointer transform hover:scale-110 duration-500 uppercase"
                    onClick={() => history.push("about")}>
                    Om Tech Labs
                  </li>
                  <li className={`py-4 `}> </li>
                </ul>
                {
                  <div className=" text-sm tracking-wider text-center text-white text-opacity-50 font-normal normal-case  py-4 absolute bottom-3 ">
                    &copy; 2022{" "}
                    <a className="font-visiarc-icon" href="https://visiarc.com/">
                      &#57345;
                    </a>
                  </div>
                }
              </div>
            </motion.div>
          )}
        </AnimatePresence>
        {/* Menu-Button right corner */}
        {/* <AiOutlineMenu
          className="absolute right-2 top-4 w-10 sm:w-14 md:w-16 h-8 text-md cursor-pointer"
          style={{ fill: "white" }}
          onClick={() => {
            setMenuOpen(!menuOpen);
          }}
        /> */}
        {/* Page */}
        <div className="py-4 w-full text-center text-xl sm:text-2xl md:text-3xl uppercase text-white font-bold tracking-widest">
          {"Tech Labs"}
        </div>
        <div className="m-2 flex flex-row flex-wrap justify-center">
          {Object.entries(categories).map((c, i) => {
            return (
              <Tag
                key={i}
                text={c[1]}
                color={"white"}
                borderColor={"#f34497"}
                active={c[1] === filter}
                onClick={() => setFilter(c[1])}
              />
            );
          })}
        </div>
        <motion.div
          initial="hidden"
          animate="show"
          variants={{
            hidden: { opacity: 0 },
            show: {
              opacity: 1,
              transition: {
                delayChildren: 0.3,
                staggerChildren: 0.3,
                ease: "easeInOut",
              },
            },
          }}
          className="flex flex-row flex-wrap justify-center overflow-x-auto content-start"
          ref={labStepContainer}
          style={{ height: `calc(${window.innerHeight}px - 68px - 52px - 15%)` }}>
          {labs.map((lab, i) => {
            if (filter === "Alla" || lab.categories.includes(filter)) {
              return <LabCard key={i} lab={lab} />;
            }
            return <div key={i}></div>;
          })}
        </motion.div>
        <div className="fixed bottom-4 text-white w-full " style={{ maxWidth: "1200px" }}>
          <hr className="w-11/12 m-auto border-t-2 " />
          <a href="https://linkopingsciencepark.se/" target="_blank" rel="noreferrer">
            <LogoLSP className="w-80 sm:h-16 sm:w-auto md:h-20 m-auto" style={{ minWidth: "320px" }} />
          </a>
          <div className="w-full flex justify-end items-center">
            <div className="text-xs sm:text-sm md:text-base text-right mr-4">
              <span className="tag" style={{ fontSize: "1rem" }}>
                Labbarna &amp; Appen
              </span>{" "}
              designad &amp; utvecklad
              <br /> åt{" "}
              <a href="https://linkopingsciencepark.se/" target="_blank" rel="noreferrer">
                Linköping Science Park
              </a>{" "}
              av{" "}
              <a className="font-visiarc-icon" href="https://visiarc.com/" target="_blank" rel="noreferrer">
                &#57345;
              </a>
              .
            </div>{" "}
            <a href="https://linkopingsciencepark.se/" target="_blank" rel="noreferrer">
              <LogoMadeInLKPG
                className="w-28 sm:w-auto sm:h-12 md:h-16 pr-4 sm:pr-6 md:pr-7"
                style={{ minWidth: "80px" }}
              />
            </a>
          </div>
        </div>
      </motion.div>
    </>
  );
}

export default Home;
