import { Lab, LabTheme } from "../../model/lab";
import { categories } from "../../utils/global";
import mobileIcon from "../../resources/svg/icons/5G-pos.svg";
import mobileBackgroundImage from "../../resources/img/backgrounds/03-dynamic.jpg";
import mobileBackDropImage from "../../resources/img/backdrops/Landscape-Noise-0.1.jpg";
import { ReactComponent as mobileNavigationBackground } from "../../resources/img/navigation/Nav-5G.svg";
import { ReactComponent as mobileNavigationPrev } from "../../resources/img/navigation/Nav-5G-Prev.svg";
import { ReactComponent as mobileNavigationNext } from "../../resources/img/navigation/Nav-Next.svg";
import MobileLabStep1 from "./MobileLabStep1";
import MobileLabStep2 from "./MobileLabStep2";
import MobileLabStep3 from "./MobileLabStep3";
import MobileLabStep4 from "./MobileLabStep4";
import MobileLabStep5 from "./MobileLabStep5";
import MobileLabStep6 from "./MobileLabStep6";

var preloadBackgroundImage = new Image();
preloadBackgroundImage.src = mobileBackgroundImage;
var preloadBackDropImage = new Image();
preloadBackDropImage.src = mobileBackDropImage;

const mobileLab = new Lab(
  "mobiltelefon-lab",
  "Mobiltelefon",
  6,
  7,
  [categories.THEORETICAL],
  { title: "3G/4G/5G", description: "Mobiltelefon" },
  { title: "Mobilkommunikation", description1: "5G/4G/3G/GSM & SMS", description2: "Mobiltelefoni & mobilt Internet" },
  [MobileLabStep1, MobileLabStep2, MobileLabStep3, MobileLabStep4, MobileLabStep5, MobileLabStep6],
  true,
);

const mobileTheme = new LabTheme(
  "#ffffff59",
  mobileBackgroundImage,
  mobileBackDropImage,
  mobileIcon,
  "linear-gradient(0deg, #9a0385, #e44847)",
  "white",
  "#fcc060",
  { backgroundUrl: mobileNavigationBackground, prevUrl: mobileNavigationPrev, nextUrl: mobileNavigationNext },
);

mobileLab.setTheme(mobileTheme);

export default mobileLab;
