import { useEffect, useRef, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import Button from "../../components/Button";
import goatVid from "../../resources/vid/goat.mp4";
import { turnOffVideoStream } from "../../utils";
import { resetSteps } from "../../utils/localstorage";

const ArLabStep11 = () => {
  const history = useHistory();
  let { labId } = useParams<any>();
  const [feedbackInfo, setFeedbackInfo] = useState("");
  const [done, setDone] = useState(false);
  const playButtonRef = useRef(null);
  const videoRef = useRef(null);
  const [showPlayButton, setShowPlayButton] = useState(false);

  useEffect(() => {
    var scene, camera, renderer;
    //  clock, deltaTime, totalTime;
    var arToolkitSource, arToolkitContext;
    var markerRoots = [];
    var patternArray;
    var animateId;

    function initialize() {
      //@ts-ignore
      scene = new THREE.Scene();

      //@ts-ignore
      const hemiLight = new THREE.HemisphereLight(0xffffff, 0x444444);
      hemiLight.position.set(0, 200, 0);
      scene.add(hemiLight);

      //@ts-ignore
      camera = new THREE.Camera();
      scene.add(camera);

      //@ts-ignore
      renderer = new THREE.WebGLRenderer({
        antialias: true,
        alpha: true,
      });
      //@ts-ignore
      renderer.setClearColor(new THREE.Color("lightgrey"), 0);
      renderer.setSize(1280, 920);
      renderer.domElement.style.position = "absolute";
      renderer.domElement.style.top = "0px";
      renderer.domElement.style.left = "0px";
      renderer.domElement.style.zIndex = "100";

      document.getElementById("ar-render-container").appendChild(renderer.domElement);

      //@ts-ignore
      // clock = new THREE.Clock();
      // deltaTime = 0;
      // totalTime = 0;

      ////////////////////////////////////////////////////////////
      // setup arToolkitSource
      ////////////////////////////////////////////////////////////

      //@ts-ignore
      arToolkitSource = new THREEx.ArToolkitSource({
        sourceType: "webcam",
      });

      function onResize() {
        if (arToolkitSource) {
          arToolkitSource.onResizeElement();
          arToolkitSource.copyElementSizeTo(renderer.domElement);
          if (arToolkitContext.arController !== null) {
            arToolkitSource.copyElementSizeTo(arToolkitContext.arController.canvas);
          }
        }
      }

      arToolkitSource.init(function onReady() {
        onResize();
      });

      // handle resize event
      window.addEventListener("resize", function () {
        onResize();
      });

      ////////////////////////////////////////////////////////////
      // setup arToolkitContext
      ////////////////////////////////////////////////////////////

      // create atToolkitContext
      //@ts-ignore
      arToolkitContext = new THREEx.ArToolkitContext({
        cameraParametersUrl: "/data/camera_para.dat",
        detectionMode: "mono",
      });

      // copy projection matrix to camera when initialization complete
      arToolkitContext.init(function onCompleted() {
        if (camera) {
          camera.projectionMatrix.copy(arToolkitContext.getProjectionMatrix());
        }
      });

      ////////////////////////////////////////////////////////////
      // setup markerRoots
      ////////////////////////////////////////////////////////////

      patternArray = [
        "0",
        "1",
        "2",
        "3",
        "4",
        "5",
        "6",
        "7",
        "8",
        "9",
        "a1",
        "a2",
        "a3",
        "b",
        "c1",
        "c2",
        "d",
        "e",
        "f",
        "g1",
        "g2",
        "h",
        "i",
        "j",
        "k",
        "l",
        "m1",
        "m2",
        "m3",
        "n",
        "o",
        "p",
        "q",
        "r",
        "s",
        "t",
        "u",
        "v",
        "w",
        "x",
        "y",
        "z",
        "å",
        "ö",
      ];

      //@ts-ignore
      var objLoader = new THREE.OBJLoader();
      objLoader.setPath("/models/");
      for (let i = 0; i < patternArray.length; i++) {
        //@ts-ignore
        let markerRoot = new THREE.Group();
        markerRoots.push(markerRoot);
        scene.add(markerRoot);
        //@ts-ignore
        new THREEx.ArMarkerControls(arToolkitContext, markerRoot, {
          type: "pattern",
          patternUrl: "/data/" + patternArray[i] + ".patt",
        });

        if (patternArray[i] === "a1") {
          // // Show video
          //@ts-ignore
          let geometry1 = new THREE.PlaneBufferGeometry(1.1, 1.1, 4, 4);
          let video = videoRef.current;
          //@ts-ignore
          let texture = new THREE.VideoTexture(video);
          //@ts-ignore
          texture.minFilter = THREE.LinearFilter;
          //@ts-ignore
          texture.magFilter = THREE.LinearFilter;
          //@ts-ignore
          texture.format = THREE.RGBFormat;
          //@ts-ignore
          let material1 = new THREE.MeshBasicMaterial({ map: texture });
          //@ts-ignore
          let mesh1 = new THREE.Mesh(geometry1, material1);
          //@ts-ignore
          mesh1.rotation.x = -Math.PI / 2;
          // // mesh1.rotation.z = +Math.PI / 2;
          markerRoot.add(mesh1);
        } else {
          //@ts-ignore
          var mtlLoader = new THREE.MTLLoader();
          mtlLoader.setPath("/models/");
          mtlLoader.load("materials.mtl", function (materials) {
            materials.preload();
            //@ts-ignore
            var objLoader = new THREE.OBJLoader();
            objLoader.setMaterials(materials);
            objLoader.setPath("/models/");
            objLoader.load("model.obj", function (object) {
              object.position.set(0.1, 0.3, 0);
              object.rotation.set(0, -Math.PI / 2, 0);
              object.scale.set(1, 1, 1);
              markerRoot.add(object);
            });
          });
        }
      }
    }

    function update() {
      // update artoolkit on every frame
      if (arToolkitSource.ready !== false) arToolkitContext.update(arToolkitSource.domElement);

      let anyVisiable = false;
      for (let i = 0; i < markerRoots.length; i++) {
        if (markerRoots[i].visible) {
          anyVisiable = true;
          if (patternArray[i] === "a1") {
            setFeedbackInfo("");
            let video = videoRef.current;
            if (video && !video.muted) {
              video.play();
            }
            if (video && video.paused) {
              setShowPlayButton(true);
            }
          } else {
            setFeedbackInfo("Nix, tyvärr. Leta vidare.");
          }
        }
      }
      if (!anyVisiable) {
        setShowPlayButton(false);
        let video = videoRef.current;
        if (video && !video.paused) {
          video.pause();
        }
      }
    }

    function render() {
      renderer.render(scene, camera);
    }

    function animate() {
      animateId = requestAnimationFrame(animate);
      // deltaTime = clock.getDelta();
      // totalTime += deltaTime;

      update();
      render();
    }

    try {
      initialize();
      animate();
    } catch (error) {
      console.log(error);
      console.error("ArLabStep11 failed to initilize THREE");
    }

    return () => {
      // clean-up
      turnOffVideoStream();
      cancelAnimationFrame(animateId);
      scene = null;
      camera = null;
      renderer = null;
      // clock = null;
      // deltaTime = null;
      // totalTime = null;
      arToolkitSource = null;
      arToolkitContext = null;
      markerRoots = null;
      patternArray = null;
    };
  }, []);

  return (
    <div className="w-full h-full">
      <video ref={videoRef} autoPlay loop muted playsInline style={{ display: "none" }}>
        <source src={goatVid} type='video/mp4; codecs="avc1.42E01E, mp4a.40.2"' />
      </video>
      <p style={{ paddingBottom: 14 }}>
        Kolla nu på filmen nedan ovanför geten. Utan <span className="abbr">MPEG</span> skulle du aldrig kunna titta på
        den här filmen. Ha ljudet på.
      </p>
      <div
        id="ar-render-container"
        style={{ height: "calc(100% - 200px)", minHeight: 200, border: "10px solid white" }}
        className="h-full relative overflow-hidden flex justify-center items-center">
        <div
          ref={playButtonRef}
          className="absolute transition-all cursor-pointer duration-1000 bg-linkping-r243g68b151 py-3 text-center text-white rounded-full"
          style={{
            zIndex: 150,
            bottom: showPlayButton ? 20 : -80,
            width: "90%",
          }}
          onClick={() => {
            let video = videoRef.current;
            video.muted = false;
            video.play();
            playButtonRef.current.style.display = "none";
            setDone(true);
          }}>
          SPELA UPP
        </div>
      </div>
      {!done && <p style={{ paddingTop: 14 }}>{feedbackInfo}</p>}
      {done && (
        <div>
          <div className="flex items-center justify-center p-8 ">
            <Button
              text={"klar"}
              onClick={() => {
                resetSteps(labId);
                history.push(`/`);
              }}
            />
          </div>
          <div className="w-full h-24"></div>
        </div>
      )}
    </div>
  );
};

export default ArLabStep11;
