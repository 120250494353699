import { useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import { setCompletedStep } from "../../utils/localstorage";
const MpegLabStep5 = () => {
  const history = useHistory();
  let { labId, labStep } = useParams<any>();
  const step = parseInt(labStep);
  useEffect(() => {
    // Step is completed right away
    setCompletedStep(labId, labStep);
  }, []);
  return (
    <div className="w-full h-full" style={{ maxWidth: 640 }}>
      <h1 className="heading-1">Digital video</h1>
      <p>
        <span className="tag">MPEG </span> (eller rörlig digital media) finns precis överallt.
        <br />
        <br />
      </p>
      <div className="p">
        <div className="flex ">
          <div className="mr-6 text-white">■</div>
          <div>
            <span className="tag">Sociala medier. </span>Grunden för appar som Snapchat och TikTok är video, och där
            användarna skapar innehållet. Utan <span className="abbr">MPEG</span>, inga sådana appar. Kanske delar du
            video med familjen och andra via Discord (ibland) eller andra tjänster, eller skickar video som meddelande.
          </div>
        </div>
        <br />
        <div className="flex ">
          <div className="mr-6 text-white">■</div>
          <div>
            <span className="tag">Videosamtal. </span> Privat använder vi videosamtal som Facetime och Duo. Men kanske
            även i skolan och på jobbet där appar som Zoom, Skype och Teams har blivit vardag.
          </div>
        </div>
        <br />
      </div>
      <p>
        <span
          className="tag text-white cursor-pointer"
          onClick={() => {
            history.push(`/${labId}/${step + 1}`);
          }}>
          fortsätt
        </span>
      </p>
      <div className="w-full h-24"></div>
    </div>
  );
};

export default MpegLabStep5;
