import { useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import { setCompletedStep } from "../../utils/localstorage";
const MpegLabStep7 = () => {
  const history = useHistory();
  let { labId, labStep } = useParams<any>();
  const step = parseInt(labStep);
  useEffect(() => {
    // Step is completed right away
    setCompletedStep(labId, labStep);
  }, []);
  return (
    <div className="w-full h-full" style={{ maxWidth: 640 }}>
      <h1 className="heading-1">Utan video</h1>
      <p>
        Utan digital video:
        <br />
        <br />
      </p>
      <div className="p">
        <div className="flex ">
          <div className="mr-6 text-white">■</div>
          <div>
            <span className="tag">Kolla på film. </span>Inte på mobilen eller surfplattan. På en gammal TV med analog
            sändning. En viss film börjar en viss tid, bara att läsa TV-tablån och passa tiden.
            <span className="abbr"> DVD</span> är digital så den går bort. Du får hyra ett kassetband, en film på{" "}
            <span className="abbr">VHS</span>
            -kassett. Har du ens sett en, annars Googla på <span className="abbr">VHS</span>? Och du måste gå till
            videoutyrningsbutiken och lämna igen filmen nästa dag också. Du kan också gå på bio.
          </div>
        </div>
        <br />
        <div className="flex ">
          <div className="mr-6 text-white">■</div>
          <div>
            <span className="tag">Internet. </span>Surfa på webben men utan att kunna kolla på film. Mindre spännande?
          </div>
        </div>
        <br />
      </div>
      <p>
        <span
          className="tag text-white cursor-pointer"
          onClick={() => {
            history.push(`/${labId}/${step + 1}`);
          }}>
          fortsätt
        </span>
      </p>
      <div className="w-full h-24"></div>
    </div>
  );
};

export default MpegLabStep7;
