import React from "react";
import ReactDOM from "react-dom";

const modalRoot = document.getElementById("root");
export class Portal extends React.Component {
  el: HTMLElement;
  constructor(props) {
    super(props);
    this.el = document.createElement("div");
  }
  componentDidMount() {
    modalRoot.appendChild(this.el);
  }
  componentWillUnmount() {
    modalRoot.removeChild(this.el);
  }
  render() {
    return ReactDOM.createPortal(this.props.children, this.el);
  }
}

export const turnOffVideoStream = () => {
  // Turn off any active video stream
  // (Activated in the AR-lab)
  const win: any = window;
  if (win.videoStream) {
    win.videoStream.getTracks().forEach(function (track) {
      track.stop();
    });
  }
};
