import { useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import { setCompletedStep } from "../../utils/localstorage";
import arCards from "./../../resources/img/Card-stack-0.2.jpg";

const ArLabStep0 = () => {
  let { labId, labStep } = useParams<any>();
  const history = useHistory();
  const step = parseInt(labStep);
  useEffect(() => {
    // Step is completed right away
    setCompletedStep(labId, labStep);
  }, []);

  return (
    <div className="w-full h-full" style={{ maxWidth: 500 }}>
      <h1 className="heading-1">AR</h1>
      <p>
        <span className="tag text-white">app. </span>
        Labben kräver ett labbkit.
        <br />
        <br />
        <span className="tag">Labbkittet </span>
        består av en serie kort med olika ikoner på som visar olika innovationer eller tech-områden.
      </p>
      <div
        className="bg-center my-8"
        style={{ backgroundImage: `url(${arCards})`, backgroundSize: "cover", height: 200 }}></div>
      {/* <img src={arCards} className="w-full"></img> */}
      <p>
        När du har dessa framför dig:
        <br />
        <br />
        <span
          className="tag text-white cursor-pointer"
          onClick={() => {
            history.push(`/${labId}/${step + 1}`);
          }}>
          fortsätt
        </span>
      </p>
      <div className="w-full h-24"></div>
    </div>
  );
};

export default ArLabStep0;
