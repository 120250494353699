import { useHistory } from "react-router-dom";
import { AnimatePresence, motion } from "framer-motion";
//SVG
import { ReactComponent as LogoLSP } from "../resources/svg/logotypes/logotype_center_heavy_white_srgb.svg";
import { ReactComponent as LogoMadeInLKPG } from "../resources/svg/logotypes/Made-in-Linkoping-neg.svg";
import { MdOutlineClose } from "react-icons/md";
import { AiOutlineMenu } from "react-icons/ai";

// Img
import backgroundImg from "../resources/img/backdrops/Abstarct-Noise-0.1.jpg";
import Navbar from "../components/Navbar";

function About() {
  const history = useHistory();
  return (
    <>
      <motion.div
        // initial={{ opacity: 0 }}
        // animate={{ opacity: 1 }}
        className="font-Source w-screen h-screen bg-c0m95y20k0 bottom-0 top-0 fixed"
        style={{ maxWidth: "1200px", backgroundImage: `url(${backgroundImg})`, backgroundSize: "cover" }}>
        {/* Page */}
        <Navbar title={"Om Tech Labs"} />
        <div
          className="flex flex-row flex-wrap justify-center overflow-x-auto content-start text-white "
          style={{ height: `calc(${window.innerHeight}px - 68px - 15%)` }}>
          <h1 className="heading-1">Text</h1>
        </div>
        <div className="fixed bottom-4 text-white w-full " style={{ maxWidth: "1200px" }}>
          <hr className="w-11/12 m-auto border-t-2 " />
          <a href="https://linkopingsciencepark.se/" target="_blank" rel="noreferrer">
            <LogoLSP className="w-80 sm:h-16 sm:w-auto md:h-20 m-auto" style={{ minWidth: "320px" }} />
          </a>
          <div className="w-full flex justify-end items-center">
            <div className="text-xs sm:text-sm md:text-base text-right mr-4">
              <span className="tag" style={{ fontSize: "1rem" }}>
                Labbarna &amp; Appen
              </span>{" "}
              designad &amp; utvecklad
              <br /> åt Linköping Science Park av{" "}
              <a className="font-visiarc-icon" href="https://visiarc.com/" target="_blank" rel="noreferrer">
                &#57345;
              </a>
              .
            </div>
            <a href="https://linkopingsciencepark.se/" target="_blank" rel="noreferrer">
              <LogoMadeInLKPG
                className="w-28 sm:w-auto sm:h-12 md:h-16 pr-4 sm:pr-6 md:pr-7"
                style={{ minWidth: "80px" }}
              />
            </a>
          </div>
        </div>
      </motion.div>
    </>
  );
}

export default About;
